import Vue from 'vue'
import Router from 'vue-router'
import profile from './views/profile.vue'
import contact from './views/contact.vue'
import home from './views/home.vue'
import hotspot from './views/hotspot.vue'
import hotspotBackup from './views/hotspotBackup.vue'
import rewards from './views/rewards.vue'
import commission from './views/commission.vue'
import login from './views/login.vue'
import register from './views/register.vue'
import help from './views/help.vue'
import portaldashboard from './views/portal/dashboard.vue'
import portalhotspot from './views/portal/hotspot.vue'
import portalreward from './views/portal/commission.vue'
import portalprofile from './views/portal/contact.vue'
import portalinvestor from './views/portal/investor.vue'
import portalcontactus from './views/portal/contactus.vue'
import portalreports from './views/portal/reports.vue'
import portalDashboardLayout from './views/portal/portaldashboardLayout.vue'
import users from './views/users.vue'
import adminusers from './views/adminuser.vue'
import DashboardLayout from './layout/dashboardLayout'
import AuthLayout from './layout/authLayout'
import forgot from './views/forgot'
import reports from './views/reports'
import emailtemplate from './views/emailtemplate'
import email from './views/email'
import schedulers from './views/schedulers'
import manageroles from './views/manageroles'
import emailsettings from './views/emailsettings'
import blacklists from './views/blacklists'
import resetpassword from './views/resetpassword'
import logs from './views/logs'
import importusers from './views/importusers';
Vue.use(Router)

const router = new Router({
    mode: 'history',
    base: process.env.BASE_URL,
    routes: [
        {
            path: '/',
            redirect: 'home',
            component: DashboardLayout,
            children: [
                {
                    path: '/',
                    name: 'home',
                    redirect: 'login',
                    component: home,
                },
                {
                    path: '/users',
                    name: 'users',
                    component: users,
                    meta: {
                      requiresAuth: true
                    }
                },
                {
                    path: '/adminusers',
                    name: 'adminusers',
                    component: adminusers,
                    meta: {
                      requiresAuth: true
                    }
                },
                {
                    path: '/user/:id',
                    name: 'user',
                    component: contact,
                    meta: {
                      requiresAuth: true
                    }
                },
                {
                    path: '/account',
                    name: 'account',
                    component: profile,
                    meta: {
                      requiresAuth: true
                    }
                },
                {
                    path: '/contact',
                    name: 'contact',
                    component: contact,
                    meta: {
                      requiresAuth: true
                    }
                },
                {
                    path: '/hotspot/:id',
                    name: 'hotspot',
                    component: hotspot,
                    meta: {
                      requiresAuth: true
                    }
                },
                {
                    path: '/hotspotbackup/:id',
                    name: 'hotspotBackup',
                    component: hotspotBackup,
                    meta: {
                      requiresAuth: true
                    }
                },
                {
                    path: '/rewards',
                    name: 'rewards',
                    component: rewards,
                    meta: {
                      requiresAuth: true
                    }
                },
                {
                    path: '/commissions',
                    name: 'commission',
                    component: commission,
                    meta: {
                      requiresAuth: true
                    }
                },
                 {
                    path: '/help',
                    name: 'help',
                    component: help,
                    meta: {
                      requiresAuth: true
                    }
                },
                {
                    path: '/register',
                    name: 'register',
                    component: register,
                    meta: {
                      requiresAuth: true
                    }
                },
                {
                    path: '/reports',
                    name: 'reports',
                    component: reports,
                    meta: {
                      requiresAuth: true
                    }
                },
                {
                    path: '/emailtemplate',
                    name: 'emailtemplate',
                    component: emailtemplate,
                    meta: {
                      requiresAuth: true
                    }
                },
                {
                    path: '/email',
                    name: 'email',
                    component: email,
                    meta: {
                      requiresAuth: true
                    }
                },
                {
                    path: '/schedulers',
                    name: 'schedulers',
                    component: schedulers,
                    meta: {
                      requiresAuth: true
                    }
                },
                {
                    path: '/emailsettings',
                    name: 'emailsettings',
                    component: emailsettings,
                    meta: {
                        requiresAuth: true
                    }
                },
                {
                    path: '/manageroles',
                    name: 'manageroles',
                    component: manageroles,
                    meta: {
                        requiresAuth: true
                    }
                },
                {
                    path: '/blacklists',
                    name: 'blacklists',
                    component: blacklists,
                    meta: {
                        requiresAuth: true
                    }
                },
                {
                    path: '/logs',
                    name: 'logs',
                    component: logs,
                    meta: {
                      requiresAuth: true
                    }
                }, 
                {
                    path: '/import/users',
                    name: 'importusers',
                    component: importusers,
                    meta: {
                      requiresAuth: true
                    }
                },       
            ]
        },
        {
            path: '/',
            redirect: 'login',
            component: AuthLayout,
            children: [
                {
                    path: '/login',
                    name: 'login',
                    component: login
                },

                {
                    path: '/forgot',
                    name: 'forgot',
                    component: forgot
                },
                {
                    path: '/:flag/password/:token',
                    name: 'resetpassword',
                    component: resetpassword,
                },

            ]
        },
        {
            path: '/',
            redirect: 'users',
            component: portalDashboardLayout,
            children: [
                {
                    path: '/portaldashboard',
                    name: 'portaldashboard',
                    component: portaldashboard,
                },
                {
                    path: '/portalhotspot',
                    name: 'portalhotspot',
                    component: portalhotspot
                },
                {
                    path: '/portalreward',
                    name: 'portalreward',
                    component: portalreward
                },
                {
                    path: '/portalprofile',
                    name: 'portalprofile',
                    component: portalprofile
                },
                {
                    path: '/portalcontactus',
                    name: 'portalcontactus',
                    component: portalcontactus
                },
                {
                    path: '/portalinvestor',
                    name: 'portalinvestor',
                    component: portalinvestor
                },
                {
                    path: '/portalreports',
                    name: 'portalreports',
                    component: portalreports
                },

            ]
        }
    
    ]
})

router.beforeEach((to, from, next) => {
    if (to.matched.some(record => record.meta.requiresAuth)) {
        // this route requires auth, check if logged in
        let User = JSON.parse(localStorage.getItem('user'));
        // if not, redirect to login page.
        if (!User) {
        next({ name: 'login' })
        } else {
        next() // go to wherever I'm going
        }
    } else {
        next() // does not require auth, make sure to always call next()!
    }
});

// export router as default
export default router;
