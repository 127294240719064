<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <v-card class="pa-3 blue darken-4">
          <div class="d-flex justify-space-between align-center">
            <div class="text-sm-h5 text-xs-h6 font-weight-bold white--text">
              Import Users
            </div>
            <v-spacer></v-spacer>
          </div>
        </v-card>
      </v-col>

      <v-col md="12 " cols="12" class="my-2">
        <v-card class="pa-2" style="height: 100%" min-height="100%">
          <v-alert type="success" v-if="readFlag == 1">
            File read successfully...
          </v-alert>
          <v-alert type="error" v-if="readFlag == 0">
            Invalid file, Please verify column names
          </v-alert>
          <v-alert type="success" v-if="importFlag == 1">
            User imported successfully...
          </v-alert>
          <v-alert type="error" v-if="importFlag == 0">
            Opps, Something went wrong...
          </v-alert>
          <label class="pa-6 font-weight-bold">Note : </label><br>
          <label class="pa-6 font-weight-bold">1. Please upload file with .xlsx, .xls, .csv extension</label><br>
          <label class="pa-6 font-weight-bold">2. File should contain listed columns [ Order Number, hotspot_network_address, Device Name, Name, customer_email, phone ]</label><br>
          <label class="pa-6 font-weight-bold">3. Column name should in same case as mentioned above</label><br>
          <label class="pa-6 font-weight-bold">4. Download sample file format <a>Click here</a></label><br>
          <v-row class="pa-6">
            <v-col class="d-flex" sm="3">
              <v-file-input
                counter
                show-size
                truncate-length="15"
                label="Choose Excel File"
                accept=".xlsx, .xls, .csv"
                v-model="selectedFile"
              >
              </v-file-input>
            </v-col>
            <v-col class="d-flex" style="justify-content: flex-start; align-items: center;" sm="3">
              <v-btn
                color="success"
                @click="handelReadFile();"
              >
                Read File
              </v-btn>
            </v-col>
            <v-col class="d-flex" style="justify-content: flex-start; align-items: center;" sm="3" v-if="readFlag == true">
              <v-btn
                color="error"
                @click="handelUpload();"
              >
                Upload Data
              </v-btn>
            </v-col>
          </v-row>
          <div class="text-sm-h6 text-xs-h6 font-weight-bold" v-if="readFlag == 1">
            Preview Data
          </div>
          <v-spacer></v-spacer>
          <v-data-table
            :headers="importHeaders"
            :items="importData"
            :items-per-page="10"
            class="elevation-1"
            v-if="readFlag == 1"
          >
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>

import axios from "axios";
import { API_BASE_URL, API_HELIUM_URL } from "../constants";
import { mapGetters } from "vuex";
import moment from "moment";
import readXlsxFile from 'read-excel-file';

export default {
  setup() {},
  data() {
    return {
      token: JSON.parse(localStorage.getItem("user")),
      selectedFile: {},
      readFlag: -1,
      importFlag: -1,
      importFailureFlag: false,
      arrayBuffer: null,
      filelist: null,
      importData: [],
      validHeaderCount: 4,
    };
  },
  watch: {
    selectedFile() {
      this.readFlag = -1;
    },
  },
  computed: {
    importHeaders() {
      return [
        { text: 'Order Number',value:'order_number'},
        { text: 'Hotspot Address', value: 'hotspot_address' },
        { text: 'Device Name', value: 'device_name' },
        { text: 'Customer Name', value: 'customer_name' },
        { text: 'Customer Email', value: 'customer_email' },
        { text: 'Customer Phone', value: 'customer_phone' }
      ]
    }
  },
  beforeMount() {
  },
  methods: {
    validateUploadedFile() {
      let fileName = this.selectedFile.name;
      if(fileName) {
        let re = /(\.xlsx|\.xls|\.csv)$/i;
        if (!re.exec(fileName)) {
          return false;
        }
        return fileName.split('.').pop();
      } else {
        return false;
      }
    },
    handelReadFile() {
      let validationRes = this.validateUploadedFile();
      if(validationRes !== false) {
        if(validationRes == "csv") {
          console.log('Processing uploaded csv file');
          this.readCSVFile();
        } else {
          console.log('Processing uploaded excel file');
          this.readExcelFile();
        }
      } else {
        console.log("Uploaded file is not supported");
      }
    },
    validateUploadedFileHeader(headers) {
      let count = 0;
      if(headers.length > 0) {
        headers.forEach((ele,key) => {
          if(ele == "hotspot_network_address" || ele == "Name" || ele == "customer_email" || ele == "phone") {
            count++;
          }
        });
      }
      return count;
    },
    createKeyValueArray(data) {
      let arr = [];
      data.forEach((element, key) => {
        if(element != undefined && element != "") {
          let arrObj = {};
          arrObj.order_number = element[0];
          arrObj.hotspot_address = element[1];
          arrObj.device_name = element[2];
          arrObj.customer_name = element[3];
          arrObj.customer_email = element[4];
          arrObj.customer_phone = element[5];
          arr.push(arrObj);
        }
      });
      return arr;
    },
    readExcelFile() {
      this.$root.isLoading = true;
      readXlsxFile(this.selectedFile).then((rows) => {
        if(rows.length > 0) {
          let headers  = rows[0];
          let headerValidationResponse = this.validateUploadedFileHeader(headers);
          if(headerValidationResponse == this.validHeaderCount) {
            rows.shift(); //Removing 0th index from array
            this.importData = this.createKeyValueArray(rows);
            this.readFlag = 1;
          } else {
            this.readFlag = 0;
          }
        }
        this.$root.isLoading = false;
      });
    },
    readCSVFile() {
      const reader = new FileReader();
      reader.readAsText(this.selectedFile);
      reader.onload = () => {
        const headers = this.CSVHeaders(reader.result);
        let headerValidationResponse = this.validateUploadedFileHeader(headers);
        if(headerValidationResponse == this.validHeaderCount) {
          const csvData = this.CSVToArray(reader.result, ',', true);
          this.importData = this.createKeyValueArray(csvData);
          this.readFlag = 1;
        }
      }
    },
    CSVHeaders(data) {
      return data.slice(0,data.indexOf('\n') + 1).replace(/\n|\r|\s/g, "").split(',');
    },
    CSVToArray(data, delimiter = ',', omitFirstRow = false) {
      return data.slice(omitFirstRow ? data.indexOf('\n') + 1 : 0)
            .split('\n')
            .map((ele) => {
              if(ele != "") {
                return ele.replace(/\n|\r/g, "").split(delimiter)
              }
            });
    },
    handelUpload() {
      if(confirm("Are you sure you want to upload data ?")) {
        this.readFlag = -1;
        this.$root.isLoading = true;
        this.uploadData();
      }
    },
    uploadData() {
      let formData = new FormData();
      formData.append('data', JSON.stringify(this.importData));
      //formData.append('file', this.selectedFile);
      axios.post(`${API_BASE_URL}/admin/import/users`, formData, {
        headers: { 
          accept: "application/json",
          //"Content-Type": "multipart/form-data",
          "x-access-token": this.token.accessToken,
        }
      })
      .then((resp) => {
        if(resp.data.status == true) {
          this.importFlag = 1;
        } else {
          this.importFlag = 0;
        }
        this.importData = [];
        this.selectedFile = {};
        this.$root.isLoading = false;
      });
    }
  }
};
</script>