<template>
  <div class="admin-main-view-sec">
    <div class="page-headings">
      <h1 class="clearfix">Hotspot</h1>
    </div>
    <div class="dash-chart-outer">
      <div class="row">
        <div class="col-sm-8">
          <div class="maphotspot" style="height: 95%" min-height="300px">
            <div class="box-head clearfix">Map Location</div>
              <iframe
                :src="mapurl"
                width="100%"
                height="90%"
                frameborder="0"
                scrolling="no"
                marginheight="0"
                marginwidth="0"
                style="border: 1px solid black"
              ></iframe>
          </div>
        </div>
        <div class="col-sm-4">
          <div class="card-style-sotspots m-b-30 offline-card red-rg-bor">
            <h2>Hotspot Name </h2>
            <p>{{ hotspotname }} <b>({{ hotspotstatus.toUpperCase() }})</b></p>
          </div>
          <div class="card-style-sotspots m-b-30 blue-rg-bor">
            <h2>Address </h2>
            <p>{{ hotspotadd }} </p>
          </div>
          <div class="card-style-sotspots m-b-30 green-rg-bor">
            <h2>Blockchain Date </h2>
            <p>{{ installation.toISOString().slice(0, 10) }}</p>
          </div>
          <div class="card-style-sotspots m-b-30 green-rg-bor">
            <h2>Installation Date </h2>
            <p v-if="installDate==null">{{ installation.toISOString().slice(0, 10) }}</p>
            <p v-else>{{ installDate.slice(0, 10) }}</p>
          </div>
          <div class="card-style-sotspots m-b-30 orange-rg-bor">
            <h3 class="hnt-tog-hsrs">
              <span class="total-ern-hotspot">{{ ((totalearn / 100) * commission).toFixed(2) }} HNT</span> 
            </h3>
            <div class="swi-tog">
              <div class="switch-new">
                <!-- <input type="checkbox" class="checkbox">
                <div class="track"></div>
                <div class="thumb"></div> -->
                <v-switch
                  v-model="swcommission"
                  inset
                >
                <v-icon>mdi-eye</v-icon></v-switch>
              </div>
              <h4> Show user reward</h4>
            </div>
            <p><i class="fa fa-star"></i> Total Reward for the hotspot in the last 3 Months </p>
            <p>
              <i class="fa fa-star"></i> Total earning 
              <span class="total-ern-hotspot">{{ commission }} %</span> 
              <span class="total-ern-user">100 %</span>
            </p>
          </div>
          <div class="card-style-sotspots m-b-30 orange-rg-bor para-content">
            <h5 class="act-assi">
              <i class="fa fa-star"></i> 
              Account assigned to this hotspot: 
              <span v-for="(item, i) in ownerinfo" :key="i">
                <span class="mail-col-1"  v-if="ownerinfo[i].role == 0 && ownerinfo[i].removeDate == null">{{ ownerinfo[i].email }}</span>
              </span>
            </h5>
            <h5 class="act-assi">
              <i class="fa fa-star"></i> 
              Account Referral to this hotspot: 
              <span v-for="(item, i) in ownerinfo" :key="i">
                <span class="mail-col-2" v-if="ownerinfo[i].role == 1">{{ ownerinfo[i].email }}</span>
              </span>
            </h5>
            <h5 class="act-assi">
              <i class="fa fa-star"></i> 
              Previously assigned to: 
              <span v-for="(item, i) in ownerinfo" :key="i">
                <span class="mail-col-3" v-if="ownerinfo[i].role == 0 && ownerinfo[i].removeDate != null">{{ ownerinfo[i].email }}</span>
              </span>
            </h5>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-12">
          <div class="card-style m-b-30">
            <div class="filter-list report-filter">
              <div class="row">
                <div class="col-sm-5">
                  <v-menu
                    ref="menu1"
                    v-model="menu1"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    max-width="290px"
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="date"
                        label="Start Date"
                        persistent-hint
                        prepend-icon="mdi-calendar"
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="date"
                      no-title
                      @input="menu1 = false"
                    ></v-date-picker>
                  </v-menu>
                  
                </div>
                <div class="col-sm-5">
                  <v-menu
                    ref="menu2"
                    v-model="menu2"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    max-width="290px"
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="date2"
                        label="End Date"
                        persistent-hint
                        prepend-icon="mdi-calendar"
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="date2"
                      no-title
                      @input="menu2 = false"
                    ></v-date-picker>
                  </v-menu>
                </div>
                <div class="col-sm-2">
                  <button @click="filtereward(date, date2)" type="button" class="btn"><i class="fas fa-check"></i> Show Rewards</button>
                </div>
              </div>
            </div>
            
            <div class="box-head clearfix">
              <v-row>
                <v-col md="6" cols="12">
                  <p>Total Rewards for the selected period</p>
                  <span style="color:#184cf8;">
                    {{ hotspotTotal.data.total.toFixed(2) }} HNT
                  </span>
                </v-col>
                <v-col md="6" cols="12">
                  <v-text-field
                    v-model="search"
                    append-icon="mdi-magnify"
                    label="Search"
                    single-line
                    hide-details
                  ></v-text-field>
                </v-col>
              </v-row>
            </div>
            <div class="all-table-theme">
              <div class="table-responsive">
                <v-data-table
                  :headers="headers"
                  :items="activity.data"
                  item-key="hash"
                  class="elevation-1"
                  :search="search"
                  :custom-filter="filterOnlyCapsText"
                >
                  <template v-slot:body.append>
                    <tr>
                      <td></td>
                      <td></td>
                      <td colspan="1"></td>
                    </tr>
                  </template>
                </v-data-table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>


<style scoped>
  @import '../assets/css/responsive.css';
  @import '../assets/css/main.css';
  /* @import '../assets/css/bootstrap.min.css'; */
</style>

<script>
import axios from "axios";
import { mapGetters } from "vuex";
import VueCookies from "vue-cookies";
import {API_BASE_URL, API_HELIUM_URL} from '../constants';
//import '../assets/css/main.css';
// import '../assets/css/animated.css';
// import '../assets/css/icons-custom.css';
//import '../assets/css/responsive.css';
//import '../assets/css/bootstrap.min.css';
// import '../assets/css/owl.theme.css';

export default {
  setup() {},
  data() {
    return {
      dialog: false,
      hotspotDetails: [],
      hotspotTotal: [],
      url: "",
      mapurl: "",
      error: false,
      start: "",
      ownerinfo: {},
      today1: "",
      totalearn: 0,
      hotspotstatus: "",
      hotspotname: "",
      hotspotadd: "",
      message: "",
      uid: this.$cookies.get("uid"),
      hname: "",
      commission: 20,
      haddress: "",
      installation: "",
      today: 0,
      sums: 0,
      installDate:"",
      yesterday: 0,
      hotspotAll: "",
      week: 0,
      token: JSON.parse(localStorage.getItem("user")),
      arr: [],
      accountTotal: [],
      rewards: [],
      search: "",
      activity: [],
      menu1: false,
      menu2: false,
      message2: "",
      date: "",
      usercommission: 0,
      date2: "",
      swcommission: false,
      dateFormatted: "",
    };
  },
  computed: {
    headers() {
      return [
        {
          text: "Hash",
          align: "start",
          sortable: false,
          value: "hash",
        },
        { text: "Time", value: "timestamp", dataType: "Date" },
        { text: "Gateway", value: "gateway" },
        { text: "Account", value: "account" },
        { text: "Amout in HNT", value: "amount" },
      ];
    },
    computedDateFormatted() {
      return this.formatDate(this.date2);
    },
  },
  watch: {
    date(val) {
      this.dateFormatted = this.formatDate(this.date);
    },
    swcommission(newValue) {
      if (newValue == true) {
        this.commission = 100;
      }
      if (newValue == false) {
        this.commission = this.usercommission;
      }
    },
  },
  beforeMount() {
    this.$root.isLoading = true;
    setTimeout(() => {
          this.$root.isLoading = false;
    },3000);
    this.getAddress()
    var start = new Date();
    var today1 = new Date();
    var dd = today1.getDate();
    var mm = today1.getMonth() + 1;
    var m2 = today1.getMonth() - 2;
    var yyyy = today1.getFullYear();
    if (dd < 10) {
      dd = "0" + dd;
    }
    if (mm < 10) {
      mm = "0" + mm;
    }
    if (m2 < 10) {
      m2 = "0" + m2;
    }
    this.today1 = yyyy + "-" + mm + "-" + dd;
    this.start = yyyy + "-" + m2 + "-" + dd;
    //this.getuid();
    //this.getHotspotName();
    var today = new Date();
    var yesterday = new Date();
    var week = new Date();
    var month = new Date();
    yesterday.setDate(today.getDate() - 1);
    week.setDate(today.getDate() - 7);
    month.setDate(today.getDate() - 30);
    this.filtereward(start, today1);
    //this.dataCard(today.toISOString().slice(0,10),yesterday.toISOString().slice(0,10),week.toISOString().slice(0,10),month.toISOString().slice(0,10));
  },
  methods: {
    openUser(name) {
      this.$router.push({ name: "user", params: { id: name } });
      //console.log(name);
    },
    getAddress() {
      axios
        .get(`${API_BASE_URL}/account`, {
          headers: {
            accept: "application/json",
            "x-access-token": this.token.accessToken,
          },
        })
        .then((resp) => {
          this.accountAdded = resp.data;
          this.getHotspot(this.$route.params.id);
        });
    },
    hotspotassign(address) {
      axios
        .get(`${API_BASE_URL}/hotspotassign/${address}`, {
          headers: {
            accept: "application/json",
            "x-access-token": this.token.accessToken,
          },
        })
        .then((resp) => {
          //console.log(resp.data)
          this.ownerinfo = resp.data;
          this.commission = resp.data[0].commission;
          this.usercommission = resp.data[0].commission;
        });
    },
    changecommission() {
      this.commission = 0;
    },
    dataCard(haddress, today, yesterday, week, month) {
      axios
        .get(
          `${API_HELIUM_URL}/v1/hotspots/${haddress}/rewards/sum?min_time=${yesterday}&max_time=${today}`,
          {
            headers: { accept: "application/json" },
          }
        )
        .then((resp) => {
          this.yesterday =
            ((resp.data.data.sum / 1000000000000).toFixed(2) / 100) *
            this.commission;
        });
      axios
        .get(
          `${API_HELIUM_URL}/v1/hotspots/${haddress}/rewards/sum?min_time=${week}&max_time=${today}`,
          {
            headers: { accept: "application/json" },
          }
        )
        .then((resp) => {
          this.week =
            ((resp.data.data.sum / 1000000000000).toFixed(2) / 100) *
            this.commission;
        });
      axios
        .get(
          `${API_HELIUM_URL}/v1/hotspots/${haddress}/rewards/sum?min_time=${today}`,
          {
            headers: { accept: "application/json" },
          }
        )
        .then((resp) => {
          this.today =
            ((resp.data.data.sum / 1000000000000).toFixed(2) / 100) *
            this.commission;
        });
      axios
        .get(
          `${API_HELIUM_URL}/v1/hotspots/${haddress}/rewards/sum?min_time=${month}&max_time=${today}`,
          {
            headers: { accept: "application/json" },
          }
        )
        .then((resp) => {
          this.sums =
            ((resp.data.data.sum / 1000000000000).toFixed(2) / 100) *
            this.commission;
        });
    },
    getHotspot(hname) {
      //Updating Hname from address
      axios
        .get(`${API_HELIUM_URL}/v1/hotspots/${hname}`, {
          headers: { accept: "application/json" },
        })
        .then((resp) => {
          const availacc = this.accountAdded.some(user => user.address == resp.data.data.owner)
          if(availacc) {
            axios.get(`${API_BASE_URL}/hotspot/${resp.data.data.address}`, {
              headers: { accept: "application/json" ,
              "x-access-token": this.token.accessToken}
            })
            .then((resp) => {
                this.installDate= resp.data[0].installDate
            })
            this.haddress = resp.data.data.address;
            //this.dataCard();
            this.hotspotassign(this.haddress);
            this.hotspotDet(this.haddress, this.start, this.today1);
          }
        })      
          //console.log(resp.data.data[0].address);
                
    },
    getHotspotName() {
      var i;
      axios
        .get(`${API_BASE_URL}/user/hotspot/${this.uid}`, {
          headers: { accept: "application/json" },
        })
        .then((resp) => {
          this.hotspotAll = resp.data;
          
          for (i = 0; i < this.hotspotAll.length; i++) {
            axios
              .get(
                `${API_HELIUM_URL}/v1/hotspots/${this.hotspotAll[i].Haddress}`,
                {
                  headers: { accept: "application/json" },
                }
              )
              .then((resp) => {
                this.arr.push(resp.data.data.name);
              });
          }
        });
    },
    getuid() {
      //this.$cookies.set('uid', this.user.data.uid)
      //this.uid = this.user.data.uid
      axios
        .get(`${API_BASE_URL}/user/hotspot/${this.uid}`, {
          headers: { accept: "application/json" },
        })
        .then((resp) => {
          try {
            this.haddress = resp.data[0].Haddress;
            this.hotspotDet(resp.data[0].Haddress, this.start, this.today1);
          } catch (err) {
            this.error = true;
            console.log("empty profile");
          }
        });
    },
    hotspotDet(address, start, today) {
      (this.dialog = false),
        axios
          .get(`${API_HELIUM_URL}/v1/hotspots/${address}`, {
            headers: { accept: "application/json" },
          })
          .then((resp) => {
            this.hotspotDetails = resp.data;
            var [year, month, day] = this.hotspotDetails.data.timestamp_added
              .substring(0, 10)
              .split("-");
            this.installation = new Date(year, month - 1, day);
            this.hname = resp.data.data.name;
            axios
              .get(
                `${API_HELIUM_URL}/v1/hotspots/${address}/rewards/sum?min_time=${this.installation
                  .toISOString()
                  .slice(0, 10)}`,
                {
                  headers: { accept: "application/json" },
                }
              )
              .then((resp) => {
                this.hotspotTotal = resp.data;
                this.totalearn = this.hotspotTotal.data.total;
                this.hotspotadd = this.hotspotDetails.data.address;
                this.hotspotstatus = this.hotspotDetails.data.status.online;
                this.hotspotname = this.hotspotDetails.data.name;
                var today = new Date();
                var yesterday = new Date();
                var week = new Date();
                var month = new Date();
                yesterday.setDate(today.getDate() - 1);
                week.setDate(today.getDate() - 7);
                month.setDate(today.getDate() - 30);
                //this.dataCard(address,today.toISOString().slice(0,10),yesterday.toISOString().slice(0,10),week.toISOString().slice(0,10),month.toISOString().slice(0,10));
                this.mapurl = [
                  "https://www.openstreetmap.org/export/embed.html?bbox=" +
                    this.hotspotDetails.data.lng +
                    "%2C" +
                    this.hotspotDetails.data.lat +
                    "%2C" +
                    this.hotspotDetails.data.lng +
                    "%2C" +
                    this.hotspotDetails.data.lat +
                    "&layer=mapnik&marker=" +
                    this.hotspotDetails.data.lat +
                    "%2C" +
                    this.hotspotDetails.data.lng,
                ].join("");
              });
          });
    },
    filtereward(start, today) {
      console.log(start),
        console.log(today),
        this.accountActivity(this.haddress, start, today);
    },
    rewardaccount(address, start, today) {
      axios
        .get(
          `${API_HELIUM_URL}/v1/accounts/${address}/rewards/sum?max_time=${today}&min_time=${start}`,
          {
            headers: { accept: "application/json" },
          }
        )
        .then((resp) => {
          this.accountTotal = resp.data;
        });
      axios
        .get(
          `${API_HELIUM_URL}/v1/accounts/${address}/rewards?max_time=${today}&min_time=${start}`,
          {
            headers: { accept: "application/json" },
          }
        )
        .then((resp) => {
          this.rewards = resp.data;
        });
    },
    accountActivity(address, start, today) {
      var cursor = [];
      axios
        .get(
          `${API_HELIUM_URL}/v1/hotspots/${address}/rewards?max_time=${today}&min_time=${start}`,
          {
            headers: { accept: "application/json" },
          }
        )
        .then((resp) => {
          axios
            .get(
              `${API_HELIUM_URL}/v1/hotspots/${address}/rewards?max_time=${today}&min_time=${start}&cursor=${resp.data.cursor}`,
              {
                headers: { accept: "application/json" },
              }
            )
            .then((resp) => {
              resp.data.data.forEach(
                (item) => (item.amount = parseInt(item.amount) / 100000000)
              );
              this.activity = resp.data;
            });
        });
      axios
        .get(
          `${API_HELIUM_URL}/v1/hotspots/${address}/rewards/sum?max_time=${today}&min_time=${start}`,
          {
            headers: { accept: "application/json" },
          }
        )
        .then((resp) => {
          this.hotspotTotal = resp.data;
        });
    },
    filterOnlyCapsText(value, search) {
      return (
        value != null &&
        search != null &&
        typeof value === "string" &&
        value.toString().replace(' ',' ').indexOf(search) !== -1
      );
    },
    formatDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split("-");
      return `${month}/${day}/${year}`;
    },
    parseDate(date) {
      if (!date) return null;

      const [month, day, year] = date.split("/");
      return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
    },
  },
};
</script>