<template>
  <v-container class="my-7">
    <v-card class="ma-auto">
      <v-row class="mx-0">
        <v-dialog
          v-model="editWithdraw"
          persistent
          max-width="1200px"
          height="1000px"
        > 
          <v-card>
            <v-card-title class="headline blue darken-4 white--text">
              Edit Withdrawal
            </v-card-title>
            <v-form ref="form4" class="mx-2" lazy-validation>
              <v-card-text class="pa-5">
                <div>
                  <v-row>
                    Amount
                    <input
                      v-model="amount"
                      color="#474DFF"
                      filled
                      clearable
                      type="number"
                    />
                    <v-select
                      :rules="nameRules"
                      :items="investname"
                      label="Name of the related investment"
                      v-model="message"
                    ></v-select>
                  </v-row>
                  <v-row> </v-row
                  ><v-row>
                    <!-- <v-select
                    :items="status"
                    label="Payment status"
                    v-model="paymentStatus"
                  ></v-select> -->
                    <v-menu
                      v-model="menu5"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="date10"
                          label="Withdrawal Date"
                          prepend-icon="mdi-calendar"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="date10"
                        @input="menu5 = false"
                      ></v-date-picker> </v-menu
                  ></v-row>
                  <v-card-actions class="pt-0">
                    <v-btn
                      text
                      dark
                      color="red"
                      @click="deleteWithdrawal(reqid)"
                    >
                      delete permanently </v-btn
                    ><v-spacer></v-spacer>
                    <v-spacer></v-spacer>

                    <v-btn text dark color="red" @click="closingWithdraw()">
                      Close
                    </v-btn>
                    <v-btn
                      text
                      dark
                      color="success"
                      @click="editWithdrawal(reqid, paymentStatus)"
                    >
                      Save
                    </v-btn>
                  </v-card-actions>
                </div>
              </v-card-text>
            </v-form>
          </v-card>
        </v-dialog>
        <v-dialog
          v-model="editInvest"
          persistent
          max-width="1200px"
          height="1000px"
        >
          <v-card>
            <v-card-title class="headline blue darken-4 white--text">
              Edit investment
            </v-card-title>
            <v-form ref="form2" class="mx-2" lazy-validation>
              <v-card-text class="pa-5">
                <div>
                  <v-row>
                    Amount
                    <input
                      :rules="nameRules"
                      v-model="amount"
                      color="#474DFF"
                      filled
                      clearable
                      type="number"
                    />
                    <v-text-field
                      :rules="nameRules"
                      label="Investment Name"
                      v-model="message"
                    ></v-text-field>
                  </v-row>
                  <v-row> </v-row>
                  <v-row>
                    <v-text-field
                      label="Hotspot Number"
                      :rules="nameRules"
                      v-model="investHotspot"
                    ></v-text-field>
                    <v-menu
                      v-model="menu8"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="date8"
                          :rules="nameRules"
                          label="Start Investment Date"
                          prepend-icon="mdi-calendar"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="date8"
                        @input="menu8 = false"
                      ></v-date-picker> </v-menu
                  ></v-row>
                  <v-row>
                    <v-text-field
                      v-model="FirstTier"
                      label="Tier 1"
                      clearable
                      type="number"
                    ></v-text-field>

                     <!-- <v-text-field
                      v-model="newSecondTier"
                      label="2xTier"
                      clearable
                      type="number"
                    ></v-text-field> -->

                     <v-text-field
                      v-model="customTier"
                      label="Tier 2 Multiplier"
                      clearable
                      type="number"
                    ></v-text-field>
                    


                    <v-text-field
                      v-model="SecondTier"
                      label="Tier 2"
                      clearable
                      type="number"
                    ></v-text-field>

                    <v-text-field
                      v-model="ThirdTier"
                      label="Lifetime Tier"
                      clearable
                      type="number"
                    ></v-text-field>
                  </v-row>
                  <v-card-actions class="pt-0">
                    <v-btn
                      text
                      dark
                      color="red"
                      @click="deleteInvestment(reqid)"
                    >
                      delete permanently </v-btn
                    ><v-spacer></v-spacer>

                    <v-btn text dark color="red" @click="closingInvestment()">
                      Close
                    </v-btn>
                    <v-btn
                      text
                      dark
                      color="success"
                      @click="editInvestment(reqid, paymentStatus)"
                    >
                      Save
                    </v-btn>
                  </v-card-actions>
                </div>
              </v-card-text>
            </v-form>
          </v-card>
        </v-dialog>

 <v-dialog
          v-model="twoFaModal"
          persistent
          max-width="400px"
          height="400px"
        >
          <v-card>
            <v-card-title class="headline blue darken-4 white--text">
              2FA Verification Code
            </v-card-title>
           
              <v-card-text class="pa-5">
                <div>
                 
                  <v-row >
                     <v-text-field
                      :rules="nameRules"
                      label="2FA Code"
                      v-model="twoFaCode"
                      
                    ></v-text-field>
                    </v-row>
             
                  <v-card-actions class="pt-0">
                   
                    <v-spacer></v-spacer>

                    <v-btn text dark color="red" @click="twoFaModal=false">
                      Close
                    </v-btn>
                    
                  </v-card-actions>
                </div>
              </v-card-text>
           
          </v-card>
        </v-dialog>
        <v-dialog
          v-model="ManualHotspot"
          persistent
          max-width="1200px"
          height="1000px"
        >
          <v-card>
            <v-card-title class="headline blue darken-4 white--text">
              Assign new Hotspot
            </v-card-title>
            <v-card-text class="pa-5">
              <div>
                <v-row>
                  <div class="cust-readie">
                  <h4 class="">Select IoT OR 5G</h4>
                  <label>
                    <input type="radio" name="bt-boxers" v-model="hotspotType" value="IoT"/>
                    <span>IoT</span>
                  </label>
                  <label>
                    <input type="radio" name="bt-boxers" v-model="hotspotType" value="5G"/>
                    <span>5G</span>
                  </label>
              </div>
                </v-row>
                <v-row>
                  <v-autocomplete
                    :items="items"
                    :item-text="getItemText"
                    :item-value="getItemValue"
                    label="Hotspot"
                    v-model="SelectedHotspot"
                    style="margin-left:5px;margin-right:5px;"
                  ></v-autocomplete>
              
                  <v-select
                    :items="HotspoType"
                    label="Hotspot Type"
                    v-model="TypeHotspot"
                    @change="mindate(SelectedHotspot)"
                    v-if="hotspotType =='IoT'"
                  ></v-select>
                  <v-select
                    :items="HotspotType5G"
                    label="Hotspot Type"
                    v-model="TypeHotspot"
                    @change="mindate(SelectedHotspot)"
                    v-if="hotspotType =='5G'"
                  ></v-select>
                  <p v-show="TypeHotspot == 'Referral Owner' && hotspotType =='IoT'">% commission</p>
                  <input
                    v-show="TypeHotspot == 'Referral Owner' && hotspotType =='IoT'"
                    filled
                    clearable
                    type="number"
                    label="commission"
                    v-model="Refcommission"
                  />

                  
                  <v-select
                    v-show="TypeHotspot == 'Referral Owner' &&  hotspotType =='IoT'"
                    label="first 30 days ernings for JAG"
                    v-model="Ref30days"
                    :items="trfal"
                  ></v-select>
                </v-row>
                  <v-row>
                     <v-text-field style="margin-left:5px;margin-right:5px;"
                    v-show="TypeHotspot == 'Fixed Rent Referral' || hotspotType =='5G'"
                    filled
                    clearable
                    type="number"
                    label="% Host Commission "
                    v-model="hostCommission"
                  ></v-text-field>
                   <v-text-field
                    v-show="TypeHotspot == 'Fixed Rent Referral' || hotspotType =='5G'"
                      v-model="fixedRent"
                      filled
                      clearable
                      label="Fixed Rent (USD)"
                      type="number"
                    ></v-text-field>
                   <p v-show="TypeHotspot == 'Fixed Rent Referral'" style="color:white;">test test</p>
                     <v-text-field
                    v-show="TypeHotspot == 'Referral Owner' && hotspotType =='5G'"
                      v-model="accountManager"
                      filled
                      clearable
                      label="Account Manager (%)"
                      type="number"
                      style="margin-left:5px;margin-right:5px;"
                    ></v-text-field>
        
                  <v-text-field
                    v-show="TypeHotspot == 'Fixed Rent Referral'"
                    filled
                    clearable
                    type="number"
                    label="% Fixed Rent Referral "
                    v-model="fixedRentReferralPercentage"
                  ></v-text-field>
                   <p v-show="TypeHotspot == 'Fixed Rent Referral'" style="color:white;">test test</p>
                 
                </v-row>
                <v-row> <h3>Optional Information</h3></v-row
                ><v-row>
                  <v-select
                    v-show="TypeHotspot == 'Primary Owner'"
                    v-model="signedHotspot"
                    :items="trfal"
                    label="Contract already signed?"
                  ></v-select>
                  <v-menu
                    ref="menu1"
                    v-model="menu1"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    max-width="290px"
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="date1Formatted"
                        label="Date"
                        hint="Manual Installation date"
                        persistent-hint
                        prepend-icon="mdi-calendar"
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="date1"
                      :min="mindat"
                      :max="nowDate"
                      @input="menu1 = false"
                    ></v-date-picker>
                  </v-menu>
                  <v-menu
                    ref="menu2"
                    v-model="menu2"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    max-width="290px"
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="date2Formatted"
                        label="Date"
                        hint="Manual remove date"
                        persistent-hint
                        prepend-icon="mdi-calendar"
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="date2"
                      :max="nowDate"
                      @input="menu2 = false"
                    ></v-date-picker>
                  </v-menu>
                </v-row>
                <v-row v-if="duplicateFlag" style="margin-top: 24px">
                  <v-alert type="error" transition="fade-transition">
                    {{ duplicateMsg }}
                  </v-alert>
                </v-row>
                <v-card-actions class="pt-0">
                  <v-spacer></v-spacer>

                  <v-btn text dark color="red" @click="closingRequest()">
                    Close
                  </v-btn>
                  <v-btn text dark color="success" @click="createHotspot()">
                    Save
                  </v-btn>
                </v-card-actions>
              </div>
            </v-card-text>
          </v-card>
        </v-dialog>

        <v-dialog
          v-model="editReq"
          persistent
          max-width="1200px"
          height="1000px"
        >
          <v-card>
            <v-card-title class="headline blue darken-4 white--text">
              Edit Hotspot
            </v-card-title>
            <v-card-text class="pa-5">
              <div>
                <v-row v-if="SelectedHotspot == 'empty'"
                  ><v-autocomplete
                    :items="items"
                    label="Hotspot"
                    v-model="SelectedHotspot"
                  ></v-autocomplete>
                </v-row>

                <v-row v-if="SelectedHotspot != 'empty'"
                  ><v-autocomplete
                    :items="items"
                    label="Hotspot"
                    v-model="SelectedHotspot"
                    disabled
                  ></v-autocomplete>
                </v-row>



                <v-row v-if="SelectedHotspot != 'empty'"
                  >

                  <v-text-field
                        v-model="HotspotHaddress"
                        label="Hotspot Address"
                        disabled
                      ></v-text-field>
                </v-row>

                <v-row v-if="SelectedHotspot != 'empty'"
                  >

                  <v-text-field
                        v-model="hotSpotLocation"
                        label="Hotspot Location"
                        disabled
                      ></v-text-field>
                </v-row>
<v-row>
                  <div class="cust-readie">
                  <h4 class="">Select IoT OR 5G</h4>
                  <label>
                    <input type="radio" name="bt-boxers" v-model="hotspotType" value="IoT"/>
                    <span>IoT</span>
                  </label>
                  <label>
                    <input type="radio" name="bt-boxers" v-model="hotspotType" value="5G"/>
                    <span>5G</span>
                  </label>
              </div>
                </v-row>
                <v-row>
                  <v-select
                    v-model="TypeHotspot"
                    :items="HotspoType"
                    label="Hotspot Type"
                     v-if="hotspotType =='IoT'"
                  ></v-select>

                   <v-select
                    :items="HotspotType5G"
                    label="Hotspot Type"
                    v-model="TypeHotspot"
                    @change="mindate(SelectedHotspot)"
                    v-if="hotspotType =='5G'"
                  ></v-select>
                  <p v-show="TypeHotspot == 'Referral Owner' && hotspotType =='IoT'">% commission</p>
                  <input
                    v-show="TypeHotspot == 'Referral Owner' && hotspotType =='IoT'"
                    filled
                    clearable
                    type="number"
                    label="commission"
                    v-model="Refcommission"
                  />
                  <v-select
                    v-show="TypeHotspot == 'Referral Owner' &&  hotspotType =='IoT'"
                    label="first 30 days ernings for JAG"
                    v-model="Ref30days"
                    :items="trfal"
                  ></v-select>

                </v-row>
                   <v-row>
                     <v-text-field style="margin-left:5px;margin-right:5px;"
                    v-show="TypeHotspot == 'Fixed Rent Referral' || hotspotType =='5G'"
                    filled
                    clearable
                    type="number"
                    label="% Host Commission "
                    v-model="hostCommission"
                  ></v-text-field>
                   <v-text-field
                    v-show="TypeHotspot == 'Fixed Rent Referral' || hotspotType =='5G'"
                      v-model="fixedRent"
                      filled
                      clearable
                      label="Fixed Rent (USD)"
                      type="number"
                    ></v-text-field>
                   <p v-show="TypeHotspot == 'Fixed Rent Referral'" style="color:white;">test test</p>
                     <v-text-field
                    v-show="TypeHotspot == 'Referral Owner' && hotspotType =='5G'"
                      v-model="accountManager"
                      filled
                      clearable
                      label="Account Manager (%)"
                      type="number"
                      style="margin-left:5px;margin-right:5px;"
                    ></v-text-field>
                   <p v-show="TypeHotspot == 'Fixed Rent Referral'" style="color:white;">test test</p>
                  <v-text-field
                    v-show="TypeHotspot == 'Fixed Rent Referral'"
                    filled
                    clearable
                    type="number"
                    label="% Fixed Rent Referral "
                    v-model="fixedRentReferralPercentage"
                  ></v-text-field>
                   <p v-show="TypeHotspot == 'Fixed Rent Referral'" style="color:white;">test test</p>
              

                  
                </v-row>
                <v-row> <h3>Optional Information</h3></v-row
                ><v-row>
                  <v-select
                    v-model="signedHotspot"
                    :items="trfal"
                    label="Contract already signed?"
                  ></v-select>
                  <v-menu
                    ref="menu3"
                    v-model="menu3"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    max-width="290px"
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="date1Formatted"
                        label="Date"
                        hint="Manual Installation date"
                        persistent-hint
                        prepend-icon="mdi-calendar"
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="date1"
                      :max="nowDate"
                      :min="mindat"
                      @input="menu3 = false"
                    ></v-date-picker>
                  </v-menu>
                  <v-menu
                    ref="menu4"
                    v-model="menu4"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    max-width="290px"
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="date2Formatted"
                        label="Date"
                        hint="Manual remove date"
                        persistent-hint
                        prepend-icon="mdi-calendar"
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="date2"
                      :max="nowDate"
                      @input="menu4 = false"
                    ></v-date-picker>
                  </v-menu>
                </v-row>
                <h3 v-show="TypeHotspot == 'Primary Owner'">
                  Installation location
                </h3>
                <v-row v-show="TypeHotspot == 'Primary Owner'">
                  <v-col>
                    <v-text-field
                      v-model="address1"
                      filled
                      clearable
                      label="Address Line"
                      type="text"
                    ></v-text-field> </v-col
                  ><v-col>
                    <v-text-field
                      v-model="city"
                      filled
                      clearable
                      label="City"
                      type="text"
                    ></v-text-field></v-col
                  ><v-col>
                    <v-text-field
                      v-model="country"
                      filled
                      clearable
                      label="Country"
                      type="text"
                    ></v-text-field></v-col
                  ><v-col>
                    <v-text-field
                      v-model="state"
                      filled
                      clearable
                      label="State"
                      type="text"
                    ></v-text-field></v-col
                  ><v-col>
                    <v-text-field
                      v-model="zip"
                      filled
                      clearable
                      label="Zip"
                      type="text"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row v-show="TypeHotspot == 'Primary Owner'">
                  <v-col>
                    <v-text-field
                      v-model="antennaType"
                      filled
                      clearable
                      label="Antenna Type"
                      type="text"
                    ></v-text-field> 
                  </v-col>
                  <v-col>
                    <v-text-field
                      v-model="antennaGain"
                      filled
                      clearable
                      label="Antenna Gain"
                      type="text"
                    >
                    </v-text-field>
                  </v-col>
                  <v-col>
                    <v-text-field
                      v-model="height"
                      filled
                      clearable
                      label="Height (m)"
                      type="number"
                    >
                    </v-text-field>
                  </v-col>
                </v-row>
                <v-card-actions class="pt-0">
                  <v-spacer></v-spacer>

                  <v-btn text dark color="red" @click="closingRequest()">
                    Close
                  </v-btn>
                  <v-btn
                    text
                    dark
                    color="success"
                    @click="saveHotspot(hotspotID)"
                  >
                    Save
                  </v-btn>
                </v-card-actions>
              </div>
            </v-card-text>
          </v-card>
        </v-dialog>

        <v-dialog v-model="hasowner" persistent max-width="600px">
          <v-card>
            <v-card-subtitle>
              <h4>
                This hotspot already has a primary user. Do you want to continue
                and override this user, therefore terminating them as the
                primary user, and making this new user the new primary owner of
                the hotspot?
              </h4>
            </v-card-subtitle>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn text dark color="red" @click="overRide()">
                Override
              </v-btn>
              <v-btn color="blue darken-1" text @click="hasowner = false">
                Close
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <v-dialog v-model="deleteHotspotReq" persistent max-width="600px">
          <v-card>
            <v-card-title class="headline purple darken-1 white--text">
            Delete Hotspot 
            </v-card-title>
            <v-card-text class="pa-3">
              <div>
                  Are you sure you want to delete this hotspot?
              </div>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn text dark color="red" @click="deleteHotspotConfirm()">
              Delete
            </v-btn>
              <v-btn color="blue darken-1" text @click="deleteHotspotReq = false">
                Cancel
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <v-col
          sm="4"
          cols="12"
          class="blue darken-2 pa-7 d-flex align-items-center rounded-left-xs"
        >
          <v-form ref="paymentInformationForm" autocomplete="off">
            <div class="headline white--text font-weight-bold my-2">
              Payment Information
            </div>
            
  

            <v-textarea
              v-model="contact.hnt_address"
              :rules="rules1.hntAddressRules"
              label="HNT"
              auto-grow
              rows="1"
              type="text"
              row-height="15"
              autocomplete="off"
            >
            </v-textarea>


            <v-textarea
              v-model="contact.eth_address"
              label="ETH"
              autocomplete="off"
              rows="1"
              type="text"
              row-height="15"
            >
            </v-textarea>
            <v-textarea
              v-model="contact.btc_address"
              label="BTC"
              rows="1"
              type="text"
              row-height="15"
              autocomplete="off"
            >
            </v-textarea>

            <v-textarea
              v-model="contact.zelle_address"
              label="Zelle"
              rows="1"
              type="text"
              row-height="15"
              autocomplete="off"
            >
            </v-textarea>
            <v-textarea
              v-model="contact.venmo_address"
              label="Venmo"
              rows="1"
              type="text"
              row-height="15"
              autocomplete="off"
            >
            </v-textarea>
            <v-row>
              <v-btn
                class="blue darken-4 white--text my-3 float-right"
                @click="save('payment_information')"
                >Save</v-btn
              ></v-row
            >
            <v-row>
              <v-textarea
                v-model="info.commission"
                label="User Commission"
                rows="1"
                type="text"
                row-height="15"
                autocomplete="off"
              >
              </v-textarea>
              <v-btn
                class="blue darken-4 white--text my-3 float-right"
                @click="saveCommission"
                >Save commission</v-btn
              ></v-row
            >
            <v-row>
              <v-select
                :items="trfal"
                v-model="FirstDays"
                label="FIRST 30 DAY EARNINGS FOR JAG"
              ></v-select>
              <v-btn
                class="blue darken-4 white--text my-3 float-right"
                @click="saveDayWaiver"
                >save</v-btn
              >
            </v-row>
            <v-row v-if="signed == 1">
              Download your signed contract:
              <v-btn
                color="blue-grey"
                class="ma-2 white--text"
                @click="getContract()"
              >
                download
                <v-icon right dark> mdi-pdf-box </v-icon>
              </v-btn>
            </v-row>
            <v-row v-else>
              there is no signed contract in our system
              <div class="file-upload">
                <input
                  accept="application/pdf"
                  type="file"
                  @change="onFileChange"
                />
                <v-btn
                  @click="onUploadFile"
                  class="upload-button success"
                  :disabled="!this.selectedFile"
                  >Upload file</v-btn
                >
              </div> </v-row
            ><!--<v-row> Manual Overwrite signed Contract</v-row>
            <v-row>
              <v-select
                :items="trfal"
                v-model="signedStatus"
                label="Contract signed?"
              ></v-select>
              <v-btn
                class="blue darken-4 white--text my-3 float-right"
                @click="saveSignedStatus"
                >save</v-btn
              > </v-row
            >-->Enable Investor Page
            <v-switch
              v-model="investorstatus"
              label=""
              color="orange darken-3"
              hide-details
            ></v-switch>
            <br />
            <v-btn @click="removeauth()" color="green">Reset 2FA code</v-btn>
            <br/>
            <br/>
          <v-btn type="button" @click="view2FaVerificationCode()" color="green">View 2FA code</v-btn>
          </v-form>
          
        </v-col>
        <v-col sm="8" cols="12" class="px-7 py-3">
          <v-row>
            <v-col cols="9">
              <v-dialog v-model="dialog" width="1200px" height="1000px">
                <!--<template v-slot:activator="{ on, attrs }">
                <v-row
                  ><v-col>
                    <v-btn
                      color="purple darken-1"
                      dark
                      v-bind="attrs"
                      v-on="on"
                    >
                      ADD multiple Hotspot
                    </v-btn></v-col
                  >
                </v-row>
              </template>-->

                <v-card>
                  <div class="headline secondary--text font-weight-bold my-2">
                    Assign multiple Hotspot
                  </div>
                  <v-card-title
                    >All the Hotspot added here will be Primary
                    Owner</v-card-title
                  >
                  <v-btn
                    class="blue darken-4 white--text my-3 float-right"
                    @click="updateOwnedHotspot"
                    >Save</v-btn
                  >
                  <v-autocomplete
                    v-model="ownedhotspot"
                    :items="items"
                    dense
                    chips
                    small-chips
                    label="Hotspots"
                    multiple
                    solo
                  ></v-autocomplete>
                </v-card>
              </v-dialog>
              <v-btn
                color="green accent-2"
                v-bind="attrs"
                v-on="on"
                @click="ManualHotspot = !ManualHotspot"
              >
                Manual Hotspot insert
              </v-btn>
            </v-col>
            <v-spacer></v-spacer>
            <v-btn
              color="purple darken-1"
              v-bind="attrs"
              v-on="on"
              @click="setcookie()"
              ><v-icon>mdi-incognito</v-icon>
              User view
            </v-btn></v-row
          >
          <!-- <v-row v-for="hotspot in ownedhotspot" :key="hotspot">
            <v-btn class="warning" @click="deleteHotspot(hotspot)"
              >{{ hotspot }}<v-icon dark right> mdi-delete </v-icon></v-btn
            >
          </v-row>-->
          <v-card-title>
            Hotspots:
            <v-spacer></v-spacer>
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Search"
              single-line
              hide-details
            ></v-text-field>
          </v-card-title>
          <v-data-table
            :headers="headers2"
            :items="hotspotAll"
            :key="ID"
            :search="search"
          >
            <template v-slot:[`item.action`]="{ item }">
              <v-btn
                fab
                dark
                x-small
                color="red accent-4"
                @click="deleteHotspot(item.hotspot_id)"
                ><v-icon dark> mdi-delete </v-icon>
              </v-btn>
              <v-btn fab dark x-small color="warning" @click="editHotspot(item)"
                ><v-icon dark> mdi-pencil-outline </v-icon></v-btn
              >
            </template>
            <template v-slot:[`item.hotspot_name`]="{ item }">
              <td>
                {{
                  item.hotspot_name
                }}
              </td>
            </template>
            <template v-slot:[`item.show`]="{ item }">
              <v-btn
                small
                color="success"
                @click="openHotspot(item.Haddress != undefined ? item.Haddress : '', item.userID)"
                >show
              </v-btn>
            </template>
            <template v-slot:[`item.commission`]="{ item }">
              <p v-if="item.role == 0">{{ info.commission }} %</p>
              <p v-else>{{ item.commission }} %</p>
            </template>
            <template v-slot:[`item.timestamp_added`]="{ item }">
              <span v-if="item.timestamp_added == null"></span>
              <span v-else>{{
                item.timestamp_added != undefined
                  ? item.timestamp_added.slice(0, 10)
                  : ""
              }}</span>
            </template>
            <template v-slot:[`item.installDate`]="{ item }">
              <span v-if="item.installDate == null">{{
                item.timestamp_added != undefined
                  ? item.timestamp_added.slice(0, 10)
                  : ""
              }}</span>
              <span v-else>{{
                item.installDate != undefined
                  ? item.installDate.slice(0, 10)
                  : ""
              }}</span>
            </template>
            <template v-slot:[`item.removeDate`]="{ item }">
              <span v-if="item.removeDate == null"></span>
              <span v-else>{{
                item.removeDate != undefined ? item.removeDate.slice(0, 10) : ""
              }}</span>
            </template>
            <template v-slot:[`item.role`]="{ item }">
              <v-chip
                class="ma-2"
                color="purple darken-1"
                dark
                v-if="item.role === '0'"
              >
                Primary Owner
              </v-chip>
              <v-chip
                class="ma-2"
                dark
                color="purple darken-4"
                v-else-if="item.role === '1'"
              >
                Referral Owner
              </v-chip>
              <v-chip
                class="ma-2"
                color="green accent-2"
                v-else-if="item.role === '2'"
              >
                Watchers
              </v-chip>
              <v-chip
                class="ma-2"
                color="green accent-2"
                v-else-if="item.role === '3'"
              >
                Fixed Rent Referral
              </v-chip>
              <v-chip class="ma-2" dark color="danger" v-else>Secondary Owner</v-chip>
            </template>
          </v-data-table>

          <v-form ref="contactInformationForm" autocomplete="off">
            <div class="headline secondary--text font-weight-bold my-2">
              Contact Information
            </div>
            <v-row>
              <v-col sm="6" cols="12">
                <v-text-field
                  v-model="contact.FirstName"
                  label="First Name*"
                  :rules="rules1.firstNameRules"
                >
                </v-text-field>
              </v-col>
              <v-col sm="6" cols="12">
                <v-text-field
                  v-model="contact.LastName"
                  label="Last Name*"
                  :rules="rules1.lastNameRules"
                >
                </v-text-field>
              </v-col>

              <v-col sm="6" cols="3">
                <v-text-field
                  v-model="contact.MailingCity"
                  label="City*"
                  autocomplete="off"
                  type="text"
                  :rules="rules1.MailingCityRules"
                >
                </v-text-field>
              </v-col>
              <v-col sm="6" cols="3">
                <v-text-field
                  v-model="contact.MailingCountry"
                  label="Country*"
                  autocomplete="off"
                  type="text"
                  :rules="rules1.MailingCountryRules"
                >
                </v-text-field>
              </v-col>
              <v-col sm="6" cols="3">
                <v-text-field
                  v-model="contact.MailingState"
                  label="State*"
                  autocomplete="off"
                  type="text"
                  :rules="rules1.MailingStateRules"
                >
                </v-text-field>
              </v-col>
              <v-col sm="6" cols="3">

                 <v-textarea
                    v-model="contact.MailingStreet"
                    label="Street*"
                    auto-grow
                    rows="1"
                    
                    row-height="15"
                    :rules="rules.MailingStreetRules"
                    
                  >
                  </v-textarea>

              </v-col>

              <v-col sm="6" cols="12">
                <v-text-field
                  v-model="contact.MailingZipCode"
                  label="Zip Code*"
                  type="text"
                  autocomplete="off"
                  :rules="rules1.MailingZipCodeRules"
                >
                </v-text-field>
              </v-col>

              <v-col sm="6" cols="12">
                <v-text-field
                  v-model="contact.phone"
                  label="Phone*"
                  autocomplete="off"
                  type="text"
                  :rules="rules1.phoneRules"
                >
                </v-text-field>
              </v-col>
              <v-col sm="6" cols="12">
                <v-autocomplete
                  v-model="contact.rank"
                  :items="rankArr"
                  :rules="rules1.rankRules"
                  label="Customer Rank"
                >
                </v-autocomplete>
              </v-col>
            </v-row>
            
            <!--<div class="headline secondary--text font-weight-bold my-2">
              Installation details:
            </div>
            <v-text-field
              v-model="contact.Wifiname"
              label="Wifiname"
              type="text"
              autocomplete="off"
            >
            </v-text-field>
            <v-text-field
              v-model="contact.Wifipassword"
              label="Wifipassword"
              type="text"
              autocomplete="off"
            >
            </v-text-field>
            <v-text-field
              v-model="contact.AntennaType"
              label="AntennaType"
              type="text"
              autocomplete="off"
            >
            </v-text-field>
            <v-text-field
              v-model="contact.AntennaLocation"
              label="AntennaLocation"
              type="text"
              autocomplete="off"
            >
            </v-text-field>
            <v-text-field
              v-model="contact.Hotspotconnection"
              label="Hotspotconnection"
              type="text"
              autocomplete="off"
            >
            </v-text-field>-->

            <v-row v-if="sucessAlert" style="margin-top: 24px">
              <v-alert type="success" transition="fade-transition">
                Your profile has been updated.
              </v-alert>
            </v-row>

            <v-btn
              class="blue darken-4 white--text my-3 float-right"
              @click="save('contact_information')"
              >Save</v-btn
            >
          </v-form>
        </v-col>
        {{ info.Antenna }}
      </v-row>
    </v-card>
    <v-card class="ma-auto">
      <v-row class="mx-0">
        <v-col sm="12" cols="12" class="px-7 py-3">
          <v-col cols="9">
            <div class="headline secondary--text font-weight-bold my-2">
              Withdrawal Information:
            </div></v-col
          >

          <p>
            Balace HNT Earned by this User:
            {{ remainbalance }} HNT
          </p>
          <p>
            Balance HNT Withdrawn by this User:
            {{ alreadypayed.toFixed(2) }} HNT
          </p>
          <p>
            Total HNT mined by user's hotspots:
            {{ allmined.toFixed(2) }} HNT
          </p>
          <p class="text-h6 gold_4--text">
            Remain HNT to withdrawal:
            {{
              (remainbalance - alreadypayed > 0) 
                ?
                  (remainbalance - alreadypayed).toFixed(2)
                :
                  0
            }}
            HNT
          </p>
          <v-card class="pa-2">
            <v-card-title>
              withdrawal requests
              <v-spacer></v-spacer>
              <v-text-field
                v-model="search2"
                append-icon="mdi-magnify"
                label="Search"
                single-line
                hide-details
              ></v-text-field>
            </v-card-title>
            <div class="pa-2">
              <v-data-table
                :headers="headers"
                :items="requestLog"
                item-key="name"
                class="elevation-1"
                :search="search2"
                :custom-filter="filterOnlyCapsText"
              >
                <template v-slot:body.append> </template>
              </v-data-table>
            </div>
          </v-card>
        </v-col>
      </v-row>
    </v-card>
    <v-snackbar v-model="snackbar" :timeout="timeout">
      {{ text }}

      <template v-slot:action="{ attrs }">
        <v-btn color="blue" text v-bind="attrs" @click="snackbar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
    <v-col v-show="investorstatus" sm="12" cols="12"
      ><v-row class="px-7 py-3 blue darken-2">
        <div
          v-show="investorstatus"
          class="headline white--text font-weight-bold my-2"
        >
          Investment Section
        </div></v-row
      >
      <v-col cols="9">
        <div class="headline secondary--text font-weight-bold my-2">
          Withdrawal Information:
        </div></v-col
      >
      <v-row md="4" cols="12">
        <v-col md="6" cols="6">
          <v-card class="my-2 border-left-4">
            <div>
              <div>
                <v-card-subtitle class="pb-0">
                  Total Invested (USD)
                </v-card-subtitle>
                <v-card-title class="text-h6 gold_4--text">
                  ${{ changeNumberFormat(total) }}
                </v-card-title>
              </div>
            </div>
          </v-card>
        </v-col>
        <v-col md="6" cols="6">
          <v-card class="my-2 border-left-4">
            <div>
              <div>
                <v-card-subtitle class="pb-0">
                  Total Paid Out (USD)
                </v-card-subtitle>
                <v-card-title class="text-h6 gold_4--text">
                  ${{ changeNumberFormat(paidouttotal) }}
                </v-card-title>
                <div v-for="item in withdrawstotal" :key="item.ID">
                  <v-card-subtitle class="pb-0">
                    {{
                      Object.keys(withdrawstotal)[
                        Object.values(withdrawstotal).indexOf(item)
                      ]
                    }}
                  </v-card-subtitle>
                  <v-card-title class="text-h6 gold_4--text">
                    ${{ changeNumberFormat(item) }}
                  </v-card-title>
                </div>
              </div>
            </div>
          </v-card>
        </v-col></v-row
      >
      <v-row md="4" cols="12">
        <v-col md="6" cols="6">
          <v-card class="my-2 border-left-4">
            <div>
              <div>
                <v-card-subtitle class="pb-0">
                  Number of Hotspots
                </v-card-subtitle>
                <v-card-title class="text-h6 gold_4--text">
                  {{ changeNumberFormat(hotspotcount) }}
                </v-card-title>
              </div>
            </div>
          </v-card>
        </v-col>
        <v-col md="6" cols="6">
          <v-card class="my-2 border-left-4">
            <div>
              <div>
                <v-card-subtitle class="pb-0">
                  Next Payout Date
                </v-card-subtitle>
                <v-card-title class="text-h6 gold_4--text">
                  {{ monthfirstDay.toLocaleDateString() }}
                </v-card-title>
                <div v-for="(item, investname) in tier" :key="item.name">
                  <v-card-subtitle class="pb-0">
                    {{ investname }}
                  </v-card-subtitle>
                  <v-card-title class="text-h6 gold_4--text">
                    ${{ changeNumberFormat(item.payout) }}
                  </v-card-title>
                  <v-data-table
                    :headers="headers4"
                    :items="investcalc[investname]"
                    :items-per-page="5"
                    class="elevation-1"
                    :custom-sort="customSort"
                  >
                    <template v-slot:item.currentMonthHotspot="{ item }">
                      {{changeNumberFormat(item.currentMonthHotspot)}}
                    </template>
                    <template v-slot:item.HNTMined="{ item }">
                      {{changeNumberFormat(item.HNTMined)}}
                    </template>
                     <template v-slot:item.HNTValue="{ item }">
                      {{changeNumberFormat(item.HNTValue)}}
                    </template>
                    <template v-slot:item.earned="{ item }">
                      {{changeNumberFormat(item.earned)}}
                    </template>
                  </v-data-table>

                  <!--<v-card-subtitle v-for="item in investcalc" :key="item.month" class="pb-0">
                    {{item}}
                  </v-card-subtitle>-->
                </div>
              </div>
            </div>
          </v-card>
        </v-col>
      </v-row>
      <v-row sm="12" cols="12" class="px-7 py-3">
        <v-col sm="6" cols="12" class="px-7 py-3">
          <v-card v-show="investorstatus">
            <v-card-title>
              Investment <v-spacer></v-spacer>
              <v-btn @click="newinvest = true" class="success">
                Enter NEW Investment </v-btn
              ><v-spacer></v-spacer>
              <v-text-field
                v-model="search3"
                append-icon="mdi-magnify"
                label="Search"
                single-line
                hide-details
              ></v-text-field>
            </v-card-title>
            <div class="pa-2">
              <v-data-table
                :headers="headers3"
                :items="investLog"
                :items-per-page="5"
                item-key="name"
                class="elevation-1"
                :search="search3"
              >
                <template v-slot:body.append> </template>
                <template v-slot:item.entered="{ item }">
                  <span>{{ formatDate(item.entered) }}</span>
                </template>
                <template v-slot:item.amount="{ item }">
                  <span>$ {{ changeNumberFormat(item.amount) }} </span> </template
                ><template v-slot:item.action="{ item }">
                  <v-btn
                    fab
                    dark
                    small
                    color="warning"
                    @click="viewInvestment(item.ID)"
                    ><v-icon dark> mdi-pencil-outline </v-icon></v-btn
                  >
                </template>
              </v-data-table>
            </div>
            <v-dialog v-model="newWithdraw" max-width="1200px" height="1000px">
              <v-card>
                <v-card-title class="headline blue darken-4 white--text">
                  Enter Withdraw Information
                </v-card-title>
                <v-form ref="form3" class="mx-2" lazy-validation>
                  <v-card-text class="pa-5">
                    <div>
                      <v-row>
                        amount
                        <input
                          :rules="nameRules"
                          v-model="amount"
                          color="#474DFF"
                          filled
                          clearable
                          type="number"
                        />
                        <v-select
                          :rules="nameRules"
                          :items="investname"
                          label="Name of the related investment"
                          v-model="message"
                        ></v-select>
                      </v-row>
                      <v-row> </v-row
                      ><v-row>
                        <!-- <v-select
                      :items="status"
                      label="Payment status"
                      v-model="paymentStatus"
                    ></v-select> -->
                        <v-menu
                          v-model="menu6"
                          :close-on-content-click="false"
                          :nudge-right="40"
                          transition="scale-transition"
                          offset-y
                          min-width="auto"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                              v-model="date7"
                              :rules="nameRules"
                              label="Start Withdraw Date"
                              prepend-icon="mdi-calendar"
                              readonly
                              v-bind="attrs"
                              v-on="on"
                            ></v-text-field>
                          </template>
                          <v-date-picker
                            v-model="date7"
                            @input="menu6 = false"
                          ></v-date-picker> </v-menu
                      ></v-row>
                      <v-card-actions class="pt-0">
                        <v-spacer></v-spacer>
                        <v-btn
                          text
                          dark
                          color="red"
                          @click="newWithdraw = false"
                        >
                          Close
                        </v-btn>
                        <v-btn
                          text
                          dark
                          color="success"
                          @click="
                            sendWithdraw(amount, paymentStatus, message, date7)
                          "
                        >
                          Save
                        </v-btn>
                      </v-card-actions>
                    </div>
                  </v-card-text>
                </v-form>
              </v-card>
            </v-dialog>
          </v-card>
        </v-col>
        <v-col sm="6" cols="12" class="px-7 py-3">
          <v-card v-show="investorstatus" sm="12" cols="12">
            <v-card-title>
              Investment Withdrawal<v-spacer></v-spacer>
              <v-btn @click="newWithdraw = true" class="success">
                Enter NEW Record </v-btn
              ><v-spacer></v-spacer>
              <v-text-field
                v-model="search3"
                append-icon="mdi-magnify"
                label="Search"
                single-line
                hide-details
              ></v-text-field>
            </v-card-title>
            <div class="pa-2">
              <v-data-table
                :headers="headers3"
                :items="withdrawalLog"
                :items-per-page="5"
                item-key="name"
                class="elevation-1"
                :search="search3"
              >
                <template v-slot:body.append> </template>
                <template v-slot:item.entered="{ item }">
                  <span>{{ formatDate(item.entered) }}</span>
                </template>
                <template v-slot:item.amount="{ item }">
                  <span>$ {{ changeNumberFormat(item.amount) }} </span> </template
                ><template v-slot:item.action="{ item }">
                  <v-btn
                    fab
                    dark
                    small
                    color="warning"
                    @click="viewWithdraw(item.ID)"
                    ><v-icon dark> mdi-pencil-outline </v-icon></v-btn
                  >
                </template>
              </v-data-table>
            </div>
            <v-dialog v-model="newinvest" max-width="1200px" height="1000px">
              <v-card>
                <v-card-title class="headline blue darken-4 white--text">
                  Enter investment
                </v-card-title>
                <v-form ref="form1" class="mx-2" lazy-validation>
                  <v-card-text class="pa-5">
                    <div>
                      <v-row>
                        amount
                        <input
                          :rules="nameRules"
                          v-model="amount"
                          color="#474DFF"
                          clearable
                          type="number"
                          required
                        />
                        <v-text-field
                          :rules="nameRules"
                          label="Name of investment"
                          v-model="message"
                          clearable
                          required
                        ></v-text-field>
                      </v-row>
                      <v-row>
                        <v-text-field
                          label="Hotspot Number"
                          :rules="nameRules"
                          v-model="investHotspot"
                        ></v-text-field>
                        <v-menu
                          v-model="menu9"
                          :close-on-content-click="false"
                          :nudge-right="40"
                          transition="scale-transition"
                          offset-y
                          min-width="auto"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                              :rules="nameRules"
                              v-model="date9"
                              label="Start Investment Date"
                              prepend-icon="mdi-calendar"
                              readonly
                              v-bind="attrs"
                              v-on="on"
                            ></v-text-field>
                          </template>
                          <v-date-picker
                            v-model="date9"
                            @input="menu9 = false"
                          ></v-date-picker> </v-menu
                      ></v-row>
                      <v-row>
                        <v-text-field
                          v-model="FirstTier"
                          label="Tier 1"
                          clearable
                          type="number"
                        ></v-text-field>

                        <!-- <v-text-field
                          v-model="newSecondTier"
                          label="2xTier"
                          clearable
                          type="number"
                        ></v-text-field> -->

                        <v-text-field
                          v-model="customTier"
                          label="Tier 2 Multiplier"
                          clearable
                          type="number"
                        ></v-text-field>

                        <v-text-field
                          v-model="SecondTier"
                          label="Tier 2"
                          clearable
                          type="number"
                        ></v-text-field>

                        <v-text-field
                          v-model="ThirdTier"
                          label="Lifetime Tier"
                          clearable
                          type="number"
                        ></v-text-field>
                      </v-row>
                      <v-card-actions class="pt-0">
                        <v-spacer></v-spacer>
                        <v-btn text dark color="red" @click="newinvest = false">
                          Close
                        </v-btn>
                        <v-btn
                          text
                          dark
                          color="success"
                          @click="
                            sendInvestment(
                              amount,
                              paymentStatus,
                              message,
                              date9,
                              FirstTier,
                              SecondTier,
                              ThirdTier,
                              newSecondTier,
                              customTier
                            )
                          "
                        >
                          Save
                        </v-btn>
                      </v-card-actions>
                    </div>
                  </v-card-text>
                </v-form>
              </v-card>
            </v-dialog>
          </v-card></v-col
        ></v-row
      ></v-col
    >
    <v-card class="pa-3" v-show="investorstatus">
      <v-card-title>Comments </v-card-title>
      <v-row class="fill-height pb-14 pa-3" align="end">
        <v-col>
          <div
            v-for="(item, index) in chat"
            :key="index"
            :class="[
              'd-flex flex-row align-center my-2',
              item.from == 'Helper' ? 'justify-end' : null,
            ]"
          >
            <span v-if="item.from == 'Helper'" class="blue--text mr-3">{{
              item.msg
            }}</span>
            <v-avatar
              :color="item.from == 'Helper' ? 'indigo' : 'red'"
              size="45"
            >
              <span class="white--text">{{ item.from[0] }}</span> </v-avatar
            ><v-btn
              fab
              dark
              x-small
              color="red accent-4"
              @click="deleteMessage(item.ID)"
              ><v-icon dark> mdi-delete </v-icon>
            </v-btn>

            <span v-if="item.from != 'Helper'" class="blue--text ml-3">{{
              item.msg
            }}</span>
          </div>
        </v-col>
      </v-row>
      <v-row no-gutters>
        <v-col>
          <div class="d-flex flex-row align-center">
            <v-text-field
              v-model="msg"
              placeholder="Type Something"
              @keypress.enter="send"
            ></v-text-field>
            <v-btn icon class="ml-4" @click="send"
              ><v-icon>mdi-send</v-icon></v-btn
            >
          </div>
        </v-col>
      </v-row>
    </v-card>
  </v-container>
</template>
<style scoped>
.asign-pop-5g .form-control {
	height: 45px;
	box-shadow: none;
	background: #fafafa;
	border-radius: 5px;
	margin-bottom: 20px;
}
.asign-pop-5g label {
	width: 100%;
	display: block;
}
.asign-pop-5g .modal-dialog {
	width: 70%;
}
.asign-pop-5g .modal-footer {
	background: #eff3f6;
}
.asign-pop-5g .modal-footer .btn {
	height: 40px;
	background: #184cf8;
	color: #fff;
	padding: 0 20px;
	text-transform: uppercase;
	letter-spacing: 1px;
}
.asign-pop-5g .modal-footer .btn:hover, .all-pop-main .modal-footer .btn:focus {
	background: #184cf8;
	color: #fff;
}
.cust-readie {
}
.cust-readie label {
	display: inline-block;
	font-size: 18px;
	cursor: pointer;
	font-weight: 500;
	position: relative;
	overflow: hidden;
	width: auto;
	margin-bottom: 0.375em;
}
.cust-readie label input {
	position: absolute;
	left: -9999px;
}
.cust-readie label input:checked + span {
	background-color: #d6d6e5;
}
.cust-readie label input:checked + span:before {
	box-shadow: inset 0 0 0 0.4375em #184cf8;
}
.cust-readie label span {
	display: flex;
	align-items: center;
	padding: 0.375em 0.75em 0.375em 0.375em;
	border-radius: 99em;
	transition: 0.25s ease;
}
.cust-readie label span:hover {background-color: #d6d6e5;
}
.cust-readie label span:before {
	display: flex;
	flex-shrink: 0;
	content: "";
	background-color: #fff;
	width: 1.5em;
	height: 1.5em;
	border-radius: 50%;
	margin-right: 0.375em;
	transition: 0.25s ease;
	box-shadow: inset 0 0 0 0.125em #00005c;
}
.date-set-list .form-control {
	height: 45px;
	box-shadow: none;
	background: #fafafa;
	border-radius: 5px;
	margin-bottom: 0px;
}
.date-set-list .input-group-addon {
	height: 40px;
	color: #fff;
	border: none;
	background: #184cf8;
}
.date-set-list .btn {
	height: 40px;
	background: #184cf8;
	color: #fff;
	width: 100%;
	padding: 0 20px;
	text-transform: uppercase;
	letter-spacing: 1px;
}
.bt-boxers {
	display: none;
}


.alfabetBox {
  display: none;
}

</style>
<script>
import axios from "axios";
import { mapGetters } from "vuex";
import VueCookies from "vue-cookies";
import { API_BASE_URL, API_HELIUM_URL, API_HELIUM_NODE_JAG_URL } from "../constants";
import moment from "moment";
export default {
  name: "App",
  data() {
    return {
      dateFormatted: this.formatDate(
        new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
          .toISOString()
          .substr(0, 10)
      ),
      rankArr: ['End User', 'RDP', 'High Valued'],
      total: 0,
      fixedRent:0,
      fixedRentReferralPercentage:0,
      hostCommission:0,
      sucessAlert: false,
      investDet: [],
      paidouttotal: 0,
      hotspotcount: 0,
      previsiontotal: 0,
      hotspottotalcount: 0,
      commission: 0,
      todaydate: new Date(),
      nowDate: new Date().toISOString().slice(0, 10),
      menu1: false,
      date1: null,
      date1Formatted: null,
      date2: null,
      date2Formatted: null,
      date3: null,
      date4: null,
      date5: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      editInvest: false,
      paymentStatus: "Completed",
      menu2: false,
      menu3: false,
      menu4: false,
      menu6: false,
      menu5: false,
      date6: null,
      date7: null,
      menu7: false,
      date8: null,
      menu8: false,
      date9: null,
      menu9: false,
      date10: null,
      menu10: false,
      investorstatus: false,
      authstatus: false,
      editWithdraw: false,
      uid: "",
      trfal: ["NO", "YES"],
      investname: [],
      Ref30days: "YES",
      investcalc:{},
      info: {},
      token: JSON.parse(localStorage.getItem("user")),
      contact: {},
      dialog: false,
      FirstDays: "YES",
      hotspotAll: [],
      arr: [],
      status: ["Pending", "Approved", "Completed"],
      signedHotspot: "",
      country: "",
      installation: "",
      hotspotID: 0,
      selectedFile: "",
      oracleprice: 0,
      mindat: null,
      editReq: false,
      newinvest: false,
      newWithdraw: false,
      remainbalance: 0,
      signedStatus: null,
      withdrawstotal: {},
      search: "",
      ManualHotspot: false,
      search2: "",
      search3: "",
      address1: "",
      FirstTier:70,
      SecondTier:20,
      newSecondTier:10,
      customTier: 0,
      ThirdTier:5,
      amount: 0,
      duplicateFlag: false,
      tier: null,
      message: "",
      city: "",
      state: "",
      zip: "",
      investLog: [],
      investHotspot: "",
      allmined: 0,
      withdrawalLog: [],
      SelectedHotspot: "",
      HotspotHaddress: "",
      installdate: "",
      Refcommission: 0,
      signed: 0,
      hotspotType: "IoT",
      TypeHotspot: "",
      hasowner: false,
      installdatetime: "",
      alreadypayed: 0,
      detailhotspot: {},
      accountManager:"",
      HotspoType: ["Primary Owner", "Referral Owner", "Watchers","Fixed Rent Referral"],
      HotspotType5G: ["Primary Owner", "Secondary Owner","Referral Owner"],
      requestLog: [],
      ownedhotspot: [],
      items: [],
      values: ["foo", "bar"],
      snackbar: false,
      text: "Save Successful",
      timeout: 4000,
      chat: {},
      rules: {
        requiredRules: [(v) => !!v || "This field is required"],
        emailRules: [
          (v) => !!v || "E-mail is required",
          (v) => /.+@.+/.test(v) || "E-mail must be valid",
        ],
      },
      duplicateMsg: "",
      attrs: "",
      on: "",
      ID: "",
      msg: "",
      nameRules: [(v) => !!v || "Required"],
      removePrimaryHotspot: '',
      deleteHotspotReq:"",
      currDeleteHotspot:"",
      antennaType:"",
      antennaGain:"",
      height:"",
      hotSpotLocation: '',
      twoFaModal: false,
      twoFaCode: "",
      rules1: {
        requiredRules: [(v) => !!v || "This field is required"],
        firstNameRules:[
            v => !!v || 'FirstName is required',
            v => !!/^[a-z]+$/i.test(v) || 'FirstName must be valid',
        ],
        lastNameRules:[
            v => !!v || 'LastName is required',
            v => !!/^[a-z]+$/i.test(v) || 'LastName must be valid',
        ],
        MailingCityRules:[
            v => !!v || 'City is required',
            v => !!/^[a-z\s]+$/i.test(v) || 'City must be valid',
        ],
        MailingCountryRules:[
            v => !!v || 'Country is required',
            v => !!/^[a-z\s]+$/i.test(v) || 'Country must be valid',
        ],

        MailingStateRules:[
            v => !!v || 'State is required',
            v => !!/^[a-z\s]+$/i.test(v) || 'State must be valid',
        ],
        MailingStreetRules:[
            v => !!v || 'Street is required',
            v => !!/^.{0,1000}$/.test(v) || 'Street must be valid',
        ],
        MailingZipCodeRules: [
            v => !!v || 'Zip is required',
            v => !!/^(\d{5})?$/.test(v) || 'Zip must be valid ',
        ],
        phoneRules: [
            v => !!v || 'Phone is required',
            v => !!/^\(?(\d{3})\)?[- ]?(\d{3})[- ]?(\d{4})$/im.test(v) || 'Phone must be valid',
        ],
        rankRules: [
            v => !!v || 'Customer rank is required',
        ],
        hntAddressRules:[
          v => !!v || 'HNT wallet address is required',
          v => !!/^.[a-zA-Z0-9]{50,50}$/.test(v) || 'Please enter valid 51 characters HNT wallet address.',
        ]
      },
    };
  },
  watch: {
    investorstatus(newValue) {
      if (newValue == true) {
        newValue = 1;
      } else {
        newValue = 0;
      }
      let formData = {
        investor: `${newValue}`,
      };
      axios
        .put(`${API_BASE_URL}/user/investor/${this.uid}`, formData, {
          headers: {
            accept: "application/json",
            "x-access-token": this.token.accessToken,
          },
        })
        .then((res) => {
          console.log(res);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    date1(val) {
      this.date1Formatted = this.formatDateNew(this.date1);
      //this.dateFormatted = this.formatDate(this.date1);
    },
    date2(val) {
      this.date2Formatted = this.formatDateNew(this.date2);
      //this.dateFormatted = this.formatDate(this.date2);
    },
    date3(val) {
      //this.dateFormatted = this.formatDate(this.date3);
    },
    date4(val) {
      this.dateFormatted = this.formatDate(this.date4);
    },
    date8(val) {
      console.log("blr = ", this.date8);
    },
  },
  computed: {
    ...mapGetters({
      user: "user",
    }),
    headers() {
      return [
        {
          text: "Request Date",
          align: "start",
          sortable: false,
          value: "entered",
        },
        {
          text: "status",
          value: "flag",
        },
        { text: "HNT payed", value: "HNTchange" },
        { text: "payment method", value: "payment" },
        { text: "amount", value: "amount" },
      ];
    },
    headers2() {
      return [
        {
          text: "Open details",
          value: "show",
          sortable: false,
          align: "center",
        },
        { text: "Name", value: "hotspot_name" },
        { text: "Type", value: "hotspotType" },
        { text: "Blockchain Date", value: "timestamp_added" },
        { text: "Online Date", value: "installDate" },
        { text: "Removal Date", value: "removeDate" },
        { text: "Commission %", value: "commission" },
        { text: "Relationship", value: "role" },
        { text: "Actions", value: "action", sortable: false, align: "center" },
      ];
    },
    headers3() {
      return [
        { text: "Investment Name", value: "payment" },
        { text: "Amount", value: "amount" },
        {
          text: "Status",
          value: "flag",
        },
        {
          text: "Created Date",
          align: "start",
          value: "entered",
        },
        { text: "Actions", value: "action", sortable: false, align: "center" },
      ];
    },
    headers4() {
      

      return[
          {
            text: 'Month',value:'month',
          },
          { text: 'Total JAG Online Hotspots', value: 'currentMonthHotspot' },
          { text: 'Total HNT Mined by JAG (Monthly)', value: 'HNTMined' },
          { text: 'HNT Monthly Average Price (USD)', value: 'HNTValue' },
          { text: 'Commission in %', value: 'commission' },
          { text: 'Monthly Earnings (USD)', value: 'earned' },
        ]
        },
    monthfirstDay() {
      return new Date(
        this.todaydate.getFullYear(),
        this.todaydate.getMonth() + 1,
        1
      );
    },
  },
  beforeMount() {
    this.createUserBillingAndAdminInfo();
    //this.getuid();
    this.getAddress();
    this.getOracleValue();

    //console.log(this.accountAdded);
  },
  methods: {
    formatDateNew(unFormatedDate) {
      let formatedDate = (unFormatedDate) ? moment(unFormatedDate).format('MM-DD-YYYY') : null;
      return formatedDate;
    },
    formatDateTime(unFormatedDateTime) {
      let formatedDateTime = (unFormatedDateTime) ? moment(unFormatedDateTime).format('MM-DD-YYYY h:mm:ss a') : null;
      return formatedDateTime;
    },
    formatHotspotName(hotspotName) {
      let hotspot_name = hotspotName.replaceAll('-',' ');
      let hotspot_name_caps = hotspot_name.split(' ').map((s) => s.charAt(0).toUpperCase() + s.substring(1)).join(' ');
      return hotspot_name_caps;
    },
    fetchUserHotspot() {
      axios.get(`${API_HELIUM_NODE_JAG_URL}/v1/user/hotspots/${this.uid}`, {
        headers: {
          accept: "application/json",
          "x-access-token": this.token.accessToken,
        },
      })
      .then((resp) => {
        let userHotspots = resp.data;
        if(userHotspots.length > 0) {
          userHotspots.forEach((element, index) => {
            element.hotspot_name_original = element.hotspot_name;
            element.hotspot_name = this.formatHotspotName(element.hotspot_name);
            element.unformated_timestamp_added = element.timestamp_added;
            element.unformated_installDate = element.installDate;
            element.unformated_removeDate = element.removeDate;
            element.timestamp_added = this.formatDateNew(element.timestamp_added);
            element.installDate = this.formatDateNew(element.installDate);
            element.removeDate = this.formatDateNew(element.removeDate);
          });
          this.hotspotAll = userHotspots;
        }
        this.loading = false;
      });
    },
    fetchUserEarnedAndMinedHNT() {
      axios.get(`${API_HELIUM_NODE_JAG_URL}/v1/user/hotspots/earning/${this.uid}`, {
        headers: {
          accept: "application/json",
          "x-access-token": this.token.accessToken,
        },
      })
      .then((resp) => {
        let userEarning = resp.data;
        this.remainbalance = userEarning[0].totalEarning;
        this.allmined = userEarning[0].totalMining;
      });
    },
    customSort(items, index, isDesc) {
      items.sort((a, b) => {
           if (index[0]=='month') {
            var d1 = new Date(a.monthFilter);
            var d2 = new Date(b.monthFilter)
            if (!isDesc[0]) {
                return d1 - d2;
            }
            else {
                return  d2 - d1;
            }
          }
          else {
            if(typeof a[index] !== 'undefined'){
               if (!isDesc[0]) {
                  return (a[index[0]] < b[index[0]]) ? -1 : 1;
              } else {
                  return (b[index[0]] < a[index[0]]) ? -1 : 1;
              }
            }
          }
      });
      return items;
    },
    getItemText(item) {
      return item.text.split(" ").map(val => val.charAt(0).toUpperCase() + val.slice(1)).join(" ");
    },
    getItemValue(item) {
      return item.value;
    },
    send: function () {
      this.chat.push({
        from: "Helper",
        msg: this.msg,
      });
      this.storeMessage(this.msg);
      this.msg = null;
    },
    view2FaVerificationCode() {
      axios
        .get(`${API_BASE_URL}/view-2fa-code/${this.uid}`, {
          headers: {
            accept: "application/json",
            "x-access-token": this.token.accessToken,
          },
        })
        .then((resp) => {
          console.log(resp.data)
         this.twoFaModal = true;
         this.twoFaCode = resp.data[0]['2fa_email_code'];
        });
    
     
    },
    removeauth() {
      let formData = {
        auth: null,
      };
      axios
        .put(`${API_BASE_URL}/editautentication/${this.uid}`, formData, {
          headers: {
            accept: "application/json",
            "x-access-token": this.token.accessToken,
          },
        })
        .then((res) => {
          console.log(res);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    storeMessage(message) {
      axios.post(`${API_BASE_URL}/message/${this.uid}`, {
        message: message,
        username: "Helper",
      });
    },
    onFileChange(e) {
      const selectedFile = e.target.files[0]; // accessing file
      this.selectedFile = selectedFile;
    },
    onUploadFile() {
      const formData = new FormData();
      formData.append("file", this.selectedFile);
      axios
        .post(`${API_BASE_URL}/upload/${this.uid}`, formData)
        .then((res) => {
          this.text = "Save Successful";
          this.snackbar = true;
          this.closingRequest();
        })
        .catch((err) => {
          console.log(err);
        });
    },
    formatDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split("-");

      return `${month}/${day}/${year}`;
    },
    parseDate(date) {
      if (!date) return null;
      let [day, month, year] = date.split("/");

      return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
    },
    openHotspot(hname, uid) {
      console.log('uid', uid);
      this.$router.push({ name: "hotspot", params: { id: hname}, query: {uid: uid} });
    },
    mindate(hname) {
      if (this.TypeHotspot == "Primary Owner") {
        axios
          .get(`${API_HELIUM_URL}/v1/hotspots/name/${hname}`, {
            headers: {
              accept: "application/json",
            },
          })
          .then((resp) => {
            var Haddress = resp.data.data[0].address;
            axios
              .get(`${API_BASE_URL}/hotspot/history/${Haddress}`, {
                headers: {
                  accept: "application/json",
                },
              })
              .then((resp2) => {
                console.log(resp2);
                if (resp2.data[0]) {
                  if (resp2.data[0].removeDate) {
                     //used timestamp_added date from the helium api for hotspot as mindat
                    //this.mindat = resp2.data[0].removeDate.substr(0, 10);
                  }
                } else {
                  //this.mindat = null;
                }
              });
          });
      }
    },
    setcookie() {
      var urlPortalUrl = "";
      if(window.location.origin == "https://staging-admin.jag.network") {
        urlPortalUrl ="https://staging-portal.jag.network";
      }
      else if(window.location.origin == "https://test-admin.jag.network") {
        urlPortalUrl ="https://test-portal.jag.network";
      }
      else if(window.location.origin == "https://supp-admin.jag.network") {
        urlPortalUrl ="https://supp-portal.jag.network";
      }
      else if(window.location.origin == "https://enh-admin.jag.network") {
        urlPortalUrl ="https://enh-portal.jag.network";
      }
      else if(window.location.origin == "https://admin.jag.network") {
        urlPortalUrl ="https://portal.jag.network";
      }
      window.open(`${urlPortalUrl}/login?userId=${this.uid}`);
    },
    editHotspot(rowdet) {
      console.log('rowdet', rowdet);
      //used as mindat 
      this.mindat = rowdet.timestamp_added != undefined ? rowdet.timestamp_added.substr(0, 10) : '';
      this.TypeHotspot = this.HotspoType[rowdet.role];
      this.SelectedHotspot = rowdet.Haddress == 'empty' ? rowdet.Haddress : rowdet.hotspot_name;
      this.HotspotHaddress = rowdet.Haddress;
      this.hotspotID = rowdet.hotspot_id;
      this.Refcommission = rowdet.commission;
      this.fixedRent = rowdet.fixedRent;
      this.hotspotType = rowdet.hotspotType;
      this.accountManager = rowdet.accountManager;
      this.hostCommission = rowdet.hostCommission;
      this.fixedRentReferralPercentage = rowdet.fixedRentReferralPercentage;
      this.Ref30days =
      this.trfal[rowdet.firstdays] != undefined ? this.trfal[rowdet.firstdays] : this.trfal[1];
      this.editReq = true;
      this.signedHotspot = this.trfal[rowdet.signed];
      this.mindate(rowdet.hotspot_name);
      //this.date1 = rowdet.installDate != null ? rowdet.installDate.substr(0,10) : "";
      //this.date2 = rowdet.removeDate != null ? rowdet.removeDate.substr(0,10) : "";
      this.date1 = rowdet.unformated_installDate != null ? rowdet.unformated_installDate : "";
      this.date2 = rowdet.unformated_removeDate != null ? rowdet.unformated_removeDate : "";
      (this.address1 = rowdet.MailingStreet),
      (this.city = rowdet.MailingCity),
      (this.state = rowdet.MailingState),
      (this.zip = rowdet.MailingZipCode),
      (this.country = rowdet.MailingCountry),
      (this.antennaType = rowdet.antennaType),
      (this.antennaGain = rowdet.antennaGain),
      (this.height = rowdet.height);
      (this.country = rowdet.MailingCountry);
      this.hotSpotLocation = rowdet.short_street +" - " +rowdet.short_state +" - " +rowdet.short_country +" - " +rowdet.short_city;
    },
    saveSignedStatus(status) {
      if (this.signedStatus != null) {
        this.signedStatus = status;
        let payload = {
          sign: this.signedStatus,
        };
        axios
          .put(`${API_BASE_URL}/editcontract/${this.uid}`, payload, {
            headers: {
              accept: "application/json",
              "x-access-token": this.token.accessToken,
            },
          })
          .then((resp) => {
            if (this.signedStatus == 0) {
              this.signedStatus = "NO";
            } else {
              this.signedStatus = "YES";
            }
          });
      }
    },
    closingRequest() {
      this.getuid();
      this.editReq = false;
      this.ManualHotspot = false;
      this.editReq = false;
      this.SelectedHotspot = "";
      this.HotspotHaddress = "";
      this.Refcommission = 0;
      this.fixedRent = 0;
      this.fixedRentReferralPercentage = 0;
      this.hostCommission = 0;
      this.Ref30days = "YES";
      this.TypeHotspot = "";
      this.duplicateFlag = false;
      this.duplicateMsg = "";
      (this.date1 = null),
        (this.date2 = null),
        (this.address1 = ""),
        (this.city = ""),
        (this.state = ""),
        (this.zip = ""),
        (this.country = ""),
        (this.signedHotspot = ""),
        (this.antennaType = ""),
        (this.antennaGain = ""),
        (this.height = "");
    },
    saveHotspot(ID) {
      

      let isEmptyHotSpot = this.hotspotAll.findIndex(
        (x) => x.ID == ID && x.Haddress == "empty"
      );

      if (isEmptyHotSpot > -1) {
        axios
          .get(`${API_HELIUM_URL}/v1/hotspots/name/${this.SelectedHotspot}`, {
            headers: {
              accept: "application/json",
            },
          })
          .then((resp) => {
            axios
              .get(
                `${API_BASE_URL}/user/hotspot/ownership/${resp.data.data[0].address}`,
                {
                  headers: {
                    accept: "application/json",
                  },
                }
              )
              .then((resp2) => {
                //console.log("alreay",this.HotspoType.indexOf(this.TypeHotspot),resp2.data[0].Status)
                if (
                  resp2.data[0].Status == 1 &&
                  this.HotspoType.indexOf(this.TypeHotspot) == 0
                ) {
                  //status 1 already have owner and type primary
                  this.hasowner = true;
                } else {
                  let sign = "";
                  if (this.signedHotspot == "") {
                    sign = "";
                  } else {
                    sign = this.trfal.indexOf(this.signedHotspot);
                  }
                  let payload = {
                    haddress: resp.data.data[0].address,
                    role: this.HotspoType.indexOf(this.TypeHotspot),
                    commission: this.Refcommission,
                    firstdays: this.trfal.indexOf(this.Ref30days),
                     fixedRentReferralPercentage: this.fixedRentReferralPercentage,
                    fixedRent: this.fixedRent,
                    hostCommission: this.hostCommission,
                    installDate: this.date1,
                    removeDate: this.date2,
                    signed: sign,
                    antennaType: this.antennaType,
                    antennaGain: this.antennaGain,
                    height: this.height,
                    hotspotType: this.hotspotType,
                    accountManager: this.accountManager,
                  };
                  axios
                    .put(`${API_BASE_URL}/edithotspot/${ID}`, payload, {
                      headers: {
                        accept: "application/json",
                        "x-access-token": this.token.accessToken,
                      },
                    })
                    .then((resp) => {
                      this.closingRequest();
                    });
                }
              });
          });
      } else {
        axios
          .get(`${API_HELIUM_URL}/v1/hotspots/${this.HotspotHaddress}`, {
            headers: {
              accept: "application/json",
            },
          })
          .then((resp) => {
             const availacc = this.accountAdded.some(
                (user) => user.address == resp.data.data.owner
              );
              console.log('availacc', availacc);
              if (availacc) {
                let sign = "";
                if (this.signedHotspot == "") {
                  sign = "";
                } else {
                  sign = this.trfal.indexOf(this.signedHotspot);
                }
                console.log('resp.data.data', resp.data.data);
                console.log('resp.data.address',resp.data.data.address);
                let payload = {
                  haddress: resp.data.data.address,
                  role: this.HotspoType.indexOf(this.TypeHotspot),
                  commission: this.Refcommission,
                  firstdays: this.trfal.indexOf(this.Ref30days),
                   fixedRentReferralPercentage: this.fixedRentReferralPercentage,
                    fixedRent: this.fixedRent,
                    hostCommission: this.hostCommission,
                  installDate: this.date1,
                  removeDate: this.date2,
                  signed: sign,
                  antennaType: this.antennaType,
                  antennaGain: this.antennaGain,
                  height: this.height,
                  hotspotType: this.hotspotType,
                  accountManager: this.accountManager,
                };
                console.log(payload);
                axios
                  .put(`${API_BASE_URL}/edithotspot/${ID}`, payload, {
                    headers: {
                      accept: "application/json",
                      "x-access-token": this.token.accessToken,
                    },
                  })
                  .then((resp) => {
                    if (sign == 0) {
                      this.saveSignedStatus(0);
                    }
                    this.closingRequest();
                  });
              }
            
          });
      }
    },
    createHotspot() {
      this.duplicateFlag = false;
      this.duplicateMsg = "";
      let role = "";
      switch (this.TypeHotspot) {
        case "Primary Owner":
          role = 0;
          break;
        case "Referral Owner":
          role = 1;
          break;
        case "Watchers":
          role = 2;
          break;
        case "Fixed Rent Referral":
          role = 3;
          break;
        default:
          console.log("Try looking up for a hint.");
      }
      //check if hotspot already exists with same name and role
      for(let i = 0; i < this.hotspotAll.length; i++) {
        console.log(this.hotspotAll[i]);
        console.log('Selected Hotspot '+this.SelectedHotspot);
        console.log('Selected Role '+role);
        console.log('Hotspot Name '+this.hotspotAll[i].hotspot_name);
        console.log('Hotspot Role '+this.hotspotAll[i].role);
        if(this.hotspotAll[i].hotspot_name_original == this.SelectedHotspot && this.hotspotAll[i].role == role) {
          this.duplicateFlag = true;
          this.duplicateMsg = `Hotspot already added as a ${this.TypeHotspot} for user.`;
          return false;
        }
      }
      if(this.duplicateFlag === false) {
        axios.get(`${API_HELIUM_NODE_JAG_URL}/v1/hotspot/details/using/name/${this.SelectedHotspot}`, {
          headers: {
            accept: "application/json",
          },
        })
        .then((resp) => {
          if(resp.data.length > 0) {
            axios.get(`${API_BASE_URL}/user/hotspot/ownership/${resp.data[0].address}`, {
              headers: {
                accept: "application/json",
              },
            })
            .then((resp2) => {
              //console.log("alreay",this.HotspoType.indexOf(this.TypeHotspot),resp2.data[0].Status)
              if (resp2.data[0].Status == 1 && this.HotspoType.indexOf(this.TypeHotspot) == 0) {
                //status 1 already have owner and type primary
                this.hasowner = true;
                this.removePrimaryHotspot = resp.data[0].address;
              } 
              else {
                let sign = "";
                if (this.signedHotspot == "") {
                  sign = "";
                } else {
                  sign = this.trfal.indexOf(this.signedHotspot);
                }
                let payload = {
                  haddress: resp.data[0].address,
                  role: role,
                  commission: this.Refcommission,
                  firstdays: this.trfal.indexOf(this.Ref30days),
                  fixedRentReferralPercentage: this.fixedRentReferralPercentage,
                  fixedRent: this.fixedRent,
                  hostCommission: this.hostCommission,
                  installDate: this.date1,
                  removeDate: this.date2,
                  signed: sign,
                  hotspotType: this.hotspotType,
                  accountManager: this.accountManager,
                };
                axios.post(`${API_BASE_URL}/newhotspot/${this.uid}`, payload, {
                  headers: {
                    accept: "application/json",
                    "x-access-token": this.token.accessToken,
                  },
                })
                .then((resp) => {
                  this.closingRequest();
                });
              }
            });
          }
          else {
            this.duplicateFlag = true;
            this.duplicateMsg = `Opps, Something went wrong, Please try after sometime.`;
            return false;
          }
        });
      }
    },
    getContract() {
      axios
        .get(`${API_BASE_URL}/user/contract/${this.uid}`, {
          headers: { accept: "application/json" },
        })
        .then((resp) => {
          try {
            console.log(resp.data);
            var id = resp.data.sid;
            window.open(`${API_BASE_URL}/download?sid=` + id, "_blank");
          } catch (err) {
            console.log("no contract signed");
          }
        });
    },
    daysInMonth(month, year) {
      return new Date(year, month, 0).getDate();
    },
    uploadPDF() {},
    getAddress() {
      axios
        .get(`${API_BASE_URL}/account`, {
          headers: {
            accept: "application/json",
            "x-access-token": this.token.accessToken,
          },
        })
        .then((resp) => {
          this.accountAdded = resp.data;
          this.getHotspots();
          //console.log('Sohel Address');
          // console.log(resp.data);
          // for (var i = 0; i < this.accountAdded.length; i++) {
          //   this.hotspotnamelist(this.accountAdded[i].address);
          // }
        });
    },
    getHotspots() {
      axios.get(`${API_HELIUM_NODE_JAG_URL}/v1/all/owned/hotspot`, {
        headers: {
          accept: "application/json",
        },
      })
      .then((resp) => {
        var hotspotaccount = resp.data.allOwnedHotspot;
        if(hotspotaccount.length > 0) {
          hotspotaccount.forEach((element, index) => {
            var obj = {};
            obj.text = element.hotspotName.replaceAll('-', ' ');
            obj.value = element.hotspotName;
            this.items.push(obj);
          });
        }
      });
    },
    saveCommission() {
      let payload = { commission: this.info.commission };
      axios
        .post(`${API_BASE_URL}/commission/${this.uid}`, payload, {
          headers: {
            accept: "application/json",
            "x-access-token": this.token.accessToken,
          },
        })
        .then((resp) => {
          this.text = "Save Successful";
          this.snackbar = true;
          console.log("done");
        });
    },
    saveDayWaiver() {
      var FirstDays;
      if (this.FirstDays == "YES") {
        FirstDays = 0;
      } else {
        FirstDays = 1;
      }
      let payload = { FirstDays: FirstDays };
      axios
        .post(`${API_BASE_URL}/firstday/${this.uid}`, payload, {
          headers: {
            accept: "application/json",
            "x-access-token": this.token.accessToken,
          },
        })
        .then((resp) => {
          this.text = "Save Successful";
          this.snackbar = true;
          console.log("done");
        });
    },
    async gettier(withdraw) {
      axios
        .get(`${API_BASE_URL}/investments/${this.uid}`, {
          headers: { accept: "application/json" },
        })
        .then(async (resp) => {
          var total = 0;
          var hotspotcount = 0;
          var investLog = resp.data;
          for (var i in investLog) {
            total += parseInt(investLog[i].amount);
            hotspotcount += parseInt(investLog[i].hotspot);
            if (investLog[i].flag == 0) {
              investLog[i].flag = "Pending";
            } else if (investLog[i].flag == 1) {
              investLog[i].flag = "Completed";
            } else if (investLog[i].flag == 2) {
              investLog[i].flag = "Deleted";
            } else if (investLog[i].flag == 3) {
              investLog[i].flag = "Approved";
            }
          }
          var investname = investLog.map(({ payment }) => payment);
          var tier = {};
          var invest = {};
          for (var y in investLog) {
            invest[investLog[y].payment] = {};
            invest[investLog[y].payment].amount = investLog[y].amount;
            invest[investLog[y].payment].hotspot = investLog[y].hotspot;
            invest[investLog[y].payment].Tier1x = investLog[y].Tier1x;
            invest[investLog[y].payment].Tier3x = investLog[y].Tier3x;
            invest[investLog[y].payment].TierLiftime = investLog[y].TierLiftime;
            invest[investLog[y].payment].customTier = investLog[y].CustomTier;
            invest[investLog[y].payment].entered = investLog[y].entered;
          }
          for (var x in invest) {
            tier[x] = {};
            tier[x]["entered"] = moment(invest[x]["entered"]).format(
              "YYYY-MM-DD"
            );
            console.log("Full details Investment")

            tier[x]["amount"] = parseInt(invest[x]["amount"]);
            tier[x]["hotspot"] = parseInt(invest[x]["hotspot"]);
            tier[x]["withdraw"] = withdraw[x];
            if (parseInt(invest[x]["amount"]) <= withdraw[x]) {
              console.log("1x");
              tier[x]["tier"] = "1x";
              tier[x]["commission"] = invest[x].Tier1x;
              if (withdraw[x] <= parseInt(invest[x]["amount"]) * (parseInt(invest[x]["customTier"])+1)) {
                console.log("3x");
                tier[x]["tier"] = "3x";
                tier[x]["commission"] = invest[x].Tier3x;
              }
              if (withdraw[x] >= parseInt(invest[x]["amount"]) * (parseInt(invest[x]["customTier"])+1)) {
                tier[x]["tier"] = "lifetime";
                tier[x]["commission"] = invest[x].TierLiftime;
              }
            } else {
              tier[x]["tier"] = "1x";
              
            }tier[x]["commission"] = invest[x].Tier1x;
            var investTable = [];
            console.log(tier[x]["entered"])
            var [year, month, day] = tier[x]["entered"].split("-");
            console.log(year, month, day)
            var days = this.daysInMonth(month, year);
            let u = await this.getMonthWiseReward(tier[x]["entered"]);
            if (u.data.length > 0) {
              let amount = 0;
              let current = 0;
              let payout = 0;
              var months = [
                "January",
                "February",
                "March",
                "April",
                "May",
                "June",
                "July",
                "August",
                "September",
                "October",
                "November",
                "December",
              ];
              //payout += tier[x]["withdraw"] ? tier[x]["withdraw"] : 0;
              for (let i = 0; i < u.data.length; i++) {
                current = 0;
                console.log(
                  "month",
                  u.data[i].month,
                  "Total Hotspot",
                  u.data[i].hotspot,
                  "Total Mined",
                  u.data[i].amount
                );
                if (i == 0) {
                  console.log("first month start on",day,"/",days)
                  current =
                    (tier[x]["hotspot"] / u.data[i].hotspot) * //hotspotcount = sum of all months u.data[i].hotspot
                    ((u.data[i].amount)/days*(days-day) / 100) * //this.previsiontotal total of all months amount
                    tier[x]["commission"] *
                    u.data[i].HNTvalue;
                  investTable.push({
                    "monthFilter": (months[u.data[i].month-1]+" "+u.data[i].year), 
                    "month":months[u.data[i].month-1]+ ', '+ u.data[i].year,
                    "Investment Hotspot": tier[x]["hotspot"],
                    currentMonthHotspot: u.data[i].hotspot,
                    HNTMined: parseFloat((u.data[i].amount)/days*(days-day)).toFixed(2),
                    HNTValue: u.data[i].HNTvalue,
                    commission: tier[x]["commission"],
                    earned: current.toFixed(2),
                  });
                } else {
                  current =
                    (tier[x]["hotspot"] / u.data[i].hotspot) * //hotspotcount = sum of all months u.data[i].hotspot
                    (u.data[i].amount / 100) * //this.previsiontotal total of all months amount
                    tier[x]["commission"] *
                    u.data[i].HNTvalue;
                  investTable.push({
                    "monthFilter": (months[u.data[i].month-1]+" "+u.data[i].year), "month":months[u.data[i].month-1]+" "+u.data[i].year,
                    "Investment Hotspot": tier[x]["hotspot"],
                    currentMonthHotspot: u.data[i].hotspot,
                    HNTMined: parseFloat(u.data[i].amount).toFixed(2),
                    HNTValue: u.data[i].HNTvalue,
                    commission: tier[x]["commission"],
                    earned: current.toFixed(2),
                  });
                }               console.log("month",u.data[i].month,"Calculation Formula","(",tier[x]["hotspot"],"/", u.data[i].hotspot,")*(",u.data[i].amount,"/100)* ",tier[x]["commission"],"*(",u.data[i].HNTvalue,")")
                console.log("pre payout:",payout,"pre current",current)
                payout += current;
                console.log("payout:",payout,"current",current)
                amount = this.calctier(invest[x]["amount"], payout, tier[x]["commission"],tier, x,u.data[i].HNTvalue,invest)//remain after passing the tier
                if(amount.remainto > 0){
                  console.log("for that month out of the tier: $",amount.remainto)
                  payout -= amount.remainto
                  current =
                  (tier[x]["hotspot"] / u.data[i].hotspot) * //hotspotcount = sum of all months u.data[i].hotspot
                  (amount.remain / 100) * //this.previsiontotal total of all months amount
                  tier[x]["commission"] *
                  (u.data[i].HNTvalue);
                  console.log("Price adjusted on the new tier: $",current)
                  investTable[investTable.length - 1]["HNTMined"]=(investTable[investTable.length - 1]["HNTMined"]-amount.remain).toFixed(2)
                  investTable[investTable.length - 1]["earned"]=(investTable[investTable.length - 1]["earned"]-amount.remainto).toFixed(2)
                  investTable.push({"monthFilter": (months[u.data[i].month-1]+" "+u.data[i].year), "month":(months[u.data[i].month-1]+", "+" "+u.data[i].year+" (Next Tier)"),"Investment Hotspot":tier[x]["hotspot"],"currentMonthHotspot":u.data[i].hotspot,"HNTMined":parseFloat(amount.remain).toFixed(2),"HNTValue":u.data[i].HNTvalue,"commission":tier[x]["commission"],"earned":(current).toFixed(2)})
                payout += current;
                }
              }
              tier[x]["amount"] = amount;
              tier[x]["payout"] = (
                payout - (tier[x]["withdraw"] ? tier[x]["withdraw"] : 0)
              ).toFixed(2); //subtract the withdraws
              console.log("total earned:",payout,"already withdraw:",(tier[x]["withdraw"] ? tier[x]["withdraw"] : 0))
              console.log("remain to Withdraw $",tier[x]["payout"])
            }
            this.investcalc[x] = investTable
          }
          this.tier = tier;
        });
    },
    calctier(amountInvested, currentStatus, perc, tier, x,hnt,invest) {
      console.log("total of investment $",amountInvested,"arrive in calculation $",currentStatus,"hntValue",hnt,invest[x])
      var remain = 0;
      var commission = 0;
      var remainto = 0
      var HNTvalue = 0
      if (currentStatus <= amountInvested && perc != invest[x].Tier3x && perc != invest[x].TierLiftime) {
        remainto = currentStatus - amountInvested
        tier[x]["tier"] = "1x";
        tier[x]["commission"] = invest[x].Tier1x;
      } 
      else if (currentStatus <= amountInvested * (parseInt(invest[x]["customTier"])+1) && perc != invest[x].Tier3x && perc != invest[x].TierLiftime) {
        remainto = currentStatus - amountInvested
        HNTvalue = remainto/hnt
        tier[x]["tier"] = "3x";
        tier[x]["commission"] = invest[x].Tier3x;
        remain = HNTvalue
        
      } else if (currentStatus >= amountInvested * (parseInt(invest[x]["customTier"])+1) && perc != invest[x].TierLiftime) {
        remainto = currentStatus - amountInvested* (parseInt(invest[x]["customTier"])+1) 
        HNTvalue = remainto/ hnt
        tier[x]["tier"] = "lifetime";
        tier[x]["commission"] = invest[x].TierLiftime;
        remain = HNTvalue
      }
      return {"remain":remain,"remainto":remainto};
    },
    getMonthWiseReward(entred) {
      return axios.get(`${API_BASE_URL}/total/reward/${entred}`, {
        headers: {
          accept: "application/json",
          "x-access-token": this.token.accessToken,
        },
      });
    },
    createUserBillingAndAdminInfo() {
      let payload = {
        userId: this.$route.params.id,
      };
      axios.post(`${API_BASE_URL}/create/user/billing/admininfo`, payload, {
        headers: {
          accept: "application/json",
          "x-access-token": this.token.accessToken,
        },
      })
      .then((resp) => {
        if(resp.data.status === true) {
          this.getuid();
        }
      });
    },
    getuid() {
      this.alreadypayed = 0;
      axios
        .get(`${API_BASE_URL}/user/${this.$route.params.id}`, {
          headers: {
            accept: "application/json",
            "x-access-token": this.token.accessToken,
          },
        })
        .then((resp) => {
          try {
            if(resp.data.length > 0) {
              this.info = resp.data[0];
              this.signed = this.info.signed;
              this.signedStatus = this.trfal[this.info.signed];
              if (resp.data[0].FirstDays == 0) {
                this.FirstDays = "YES";
              } else {
                this.FirstDays = "NO";
              }
              this.uid = resp.data[0].UserID;
              console.log("invest", this.info.investor);
              if (this.info.investor == "0" || this.info.investor == undefined) {
                this.investorstatus = false;
              } else {
                this.investorstatus = true;
              }
              console.log("after", this.investor);
              //this.populateAdminField(resp.data[0]);
              //this.getHotspotName();
              //function to fetch user hotspots
              this.fetchUserHotspot();
              //function to fetch user earnings
              this.fetchUserEarnedAndMinedHNT();
              this.getpayment();
              this.getRequest();
              this.getwithdrawal();
              this.getInvest();
              this.getchat();
            }
            
          } catch (err) {
            console.log("empty profile");
          }
        });
    },
    getchat() {
      axios
        .get(`${API_BASE_URL}/user/investor/chat/${this.uid}`, {
          headers: { accept: "application/json" },
        })
        .then((resp) => {
          console.log(resp);
          var res = [];
          for (var x in resp.data) {
            res.push({
              from: resp.data[x].name,
              msg: resp.data[x].message,
              datetime: resp.data[x].entered,
              ID: resp.data[x].ID,
            });
          }
          this.chat = res;
        });
    },
    getpayment() {
      axios
        .get(`${API_BASE_URL}/user/payment/${this.uid}`, {
          headers: {
            accept: "application/json",
          },
        })
        .then((resp) => {
          try {
            this.populateField(resp.data[0]);
            this.populateAdminField(resp.data[0]);
          } catch (err) {
            console.log("empty profile");
          }
        });
    },
    getRequest() {
      axios
        .get(`${API_BASE_URL}/user/request/${this.uid}`, {
          headers: { accept: "application/json" },
        })
        .then((resp) => {
          this.requestLog = resp.data;
          for (var i in this.requestLog) {
            this.requestLog[i].entered = this.formatDateTime(this.requestLog[i].entered);
            if (this.requestLog[i].payment == "HNT") {
              this.requestLog[i].HNTchange = this.requestLog[i].amount;
            }
            //this.alreadypayed += parseFloat(this.requestLog[i].HNTchange);
            if (this.requestLog[i].flag == 0) {
              this.requestLog[i].flag = "Pending";
            } else if (this.requestLog[i].flag == 1) {
              this.alreadypayed += parseFloat(this.requestLog[i].HNTchange);
              this.requestLog[i].flag = "Completed";
            } else if (this.requestLog[i].flag == 2) {
              this.requestLog[i].flag = "Deleted";
            } else if (this.requestLog[i].flag == 3) {
              this.requestLog[i].flag = "Approved";
            }
          }
        });
    },
    hotspotnamelist(address) {
      axios
        .get(`${API_HELIUM_URL}/v1/accounts/${address}/hotspots`, {
          headers: {
            accept: "application/json",
          },
        })
        .then((resp) => {
          var hotspotaccount = resp.data;
          var arr = [];
          for (var i = 0; i < hotspotaccount.data.length; i++) {
            //this.items.push(hotspotaccount.data[i].name);
            var obj = {};
            obj.text = hotspotaccount.data[i].name.replaceAll('-', ' ');
            obj.value = hotspotaccount.data[i].name;
            this.items.push(obj);
          }
        });
    },
    getHotspotName() {
      axios
        .get(`${API_BASE_URL}/user/hotspot/${this.uid}`, {
          headers: {
            accept: "application/json",
          },
        })
        .then((resp) => {
          this.parsingName(resp.data);
        });
    },
    parsingName(arr3) {
      var arr1 = [];
      var arr2 = [];
      let promises = [];
      var status = 0;
      var index = 0;
      arr3.forEach((element) => {
        if (element.role == 0) {
          status += parseInt(element.signed);
          index += 1;
        }
      });
      console.log(status, index);
      if (status == index) {
        //if only 1 hotspot have a unsigned hotspot the global status is marked as
        this.saveSignedStatus(1);
      } else {
        this.saveSignedStatus(0);
      }
      arr3.forEach((element) => {
        if (element.Haddress != "empty") {
          promises.push(
            axios.get(`${API_HELIUM_URL}/v1/hotspots/${element.Haddress}`, {
              headers: { accept: "application/json" },
            })
          );
        }

        Promise.all(promises).then((results) => {
          console.log("results", results);
          results.forEach((resp) => {
            element["name"] = resp.data.data.name;
          });
          console.log("All done!");
        });
      });
      Promise.all(promises).then((results) => {
        results.forEach((resp) => {
          arr2.push(resp.data.data);
          arr1.push(resp.data.data.name);
        });
        this.remainpayment(arr1, arr2, arr3);
        console.log("All done!");
      });
    },
    filterOnlyCapsText(value, search, item) {
      return (
        value != null &&
        search != null &&
        typeof value === "string" &&
        value.toString().replace("-", " ").indexOf(search) !== -1
      );
    },
    remainpayment(arr1, arr2, arr3) {
      console.log('In remain payment');
      this.ownedhotspot = arr1;
      this.detailhotspot = arr2;
      this.hotspotAll = arr3;
      var i;
      var conc = {};
      let multipleSum = 0;
      let details = this.detailhotspot;
      this.remainbalance = 0;
      for (i = 0; i < details.length; i++) {
        for (var attrname in this.hotspotAll[i]) {
          conc[attrname] = this.hotspotAll[i][attrname];
        }
        for (var attrname1 in details[i]) {
          conc[attrname1] = details[i][attrname1];
        }
        this.hotspotAll[i] = conc;
        conc = {};
      }
      this.hotspotAll.forEach((element) => {
        if (element.Haddress != "empty") {
          var endinst = null;
          var [year, month, day] = element.timestamp_added
            .substring(0, 10)
            .split("-");
          console.log('timestamp_added '+year, month, day);
          var installation = new Date(year, month - 1, day);
          if (element.installDate != null) {
            this.installdatetime = element.installDate.slice(0, 10);
          } else {
            this.installdatetime = installation.toISOString().substring(0, 10);
          }
          console.log('modified_timestamp '+this.installdatetime);
          if (element.removeDate != null) {
            endinst = "&max_time=" + element.removeDate.slice(0, 10);
          }
          this.installdatetime = this.checkfirstdays(
            element.role,
            this.installdatetime,
            element.firstdays
          );
          console.log('modified install date '+this.installdatetime);
          if (element.role == 1) {
            //if refer owner
            if (element.firstdays == 0) {
              this.getbalance(
                element.address,
                this.installdatetime,
                endinst,
                element.commission,
                "refer"
              );
            } else {
              this.getbalance(
                element.address,
                this.installdatetime,
                endinst,
                element.commission,
                "refer"
              );
            }
          }
          if (element.role == 0) {
            //if primary owner
            if (this.FirstDays == "NO") {
              this.getbalance(
                element.address,
                this.installdatetime,
                endinst,
                this.info.commission,
                "primary"
              );
            } else {
              this.getbalance(
                element.address,
                this.installdatetime,
                endinst,
                this.info.commission,
                "primary"
              );
            }
          }
        }
      });
      console.log(this.hotspotAll);
    },
    checkfirstdays(role, startdate, firstdays) {
      var [year, month, day] = startdate.substring(0, 10).split("-");
      var installation = new Date();
      if (role == 1) {
        if (firstdays == 0) {
          //30 days NO
          installation = new Date(year, month - 1, day);
          installation.setDate(installation.getDate() + 1);
        } else {
          //30 days YES
          installation = new Date(year, month, day);
          installation.setDate(installation.getDate() + 1);
        }
      } else {
        //primary owner
        if (this.FirstDays == "YES") {
          //30 days YES
          installation = new Date(year, month, day);
          installation.setDate(installation.getDate() + 1);
        } else {
          installation = new Date(year, month - 1, day);
          installation.setDate(installation.getDate() + 1);
        }
      }
      this.installation = installation.toISOString().substring(0, 10);
      return installation.toISOString().substring(0, 10);
    },
    getbalance(address, dateinst, endinst, commission, type) {
      var final_comm = 0;
      if (type == "refer") {
        axios
          .get(`${API_BASE_URL}/user/hotspot/own/${address}`, {
            headers: {
              accept: "application/json",
            },
          })
          .then((resp) => {
            if (resp.data.length != 0) {
              final_comm = resp.data[0].commission;
            } else {
              final_comm = 20;
            }
            this.getbalanceStep2(
              address,
              dateinst,
              endinst,
              commission,
              final_comm
            );
          });
      } else {
        this.getbalanceStep2(
          address,
          dateinst,
          endinst,
          commission,
          final_comm
        );
      }
    },
    getbalanceStep2(address, dateinst, endinst, commission, final_comm) {
      if (endinst == "null" || endinst == null) {
        endinst = "";
      }
      axios
        .get(
          `${API_HELIUM_URL}/v1/hotspots/${address}/rewards/sum?min_time=${dateinst.slice(
            0,
            10
          )}${endinst}`,
          {
            headers: {
              accept: "application/json",
            },
          }
        )
        .then((resp) => {
          this.remainbalance +=
            ((resp.data.data.total -
              (resp.data.data.total / 100) * final_comm) /
              100) *
            commission;
          this.allmined += resp.data.data.total;
          // console.log( "total",resp.data.data.total,"final comm",final_comm,"commisison",commission,"addr",address);
        })
        .catch((resp) => {
          this.remainbalance += 0;
        });
    },
    deleteHotspot(hID) {
      this.deleteHotspotReq = true;
      this.currDeleteHotspot = hID;
    },
    deleteHotspotConfirm() {
      this.deleteHotspotReq = false;
      
      axios
        .delete(
          `${API_BASE_URL}/hotspot/${this.currDeleteHotspot}`,
          {
            headers: {
              accept: "application/json",
            },
          }
        )
        .then((resp) => {
          this.text = "Delete suceessfully";
          this.snackbar = true;
          this.getuid();
        });
    },
    sendWithdraw(amount, status, message, startdate) {
      this.$refs.form3.validate();

      if (!amount || !message || !startdate) return false;

      let payload = {
        payment: message,
        amount: amount,
        flag: 1,
        create: startdate,
      };
      console.log(payload);
      axios
        .post(`${API_BASE_URL}/newwithdraw/${this.uid}`, payload, {
          headers: {
            accept: "application/json",
            "x-access-token": this.token.accessToken,
          },
        })
        .then((resp) => {
          this.newWithdraw = false;
          console.log(resp);
          this.getwithdrawal();
          this.message = "";
          this.amount = 0;
        });
    },
    getJAGstatus() {
      axios
        .get(`${API_BASE_URL}/total/reward`, {
          headers: {
            accept: "application/json",
            "x-access-token": this.token.accessToken,
          },
        })
        .then((resp) => {
          this.hotspottotalcount = resp.data.hotspot;
          this.previsiontotal = resp.data.balance;
        });
    },
    viewWithdraw(ID) {
      this.reqid = ID;
      console.log(ID);
      this.editWithdraw = true;
      axios
        .get(`${API_BASE_URL}/withdraw/${ID}`, {
          headers: {
            accept: "application/json",
            "x-access-token": this.token.accessToken,
          },
        })
        .then((resp) => {
          console.log(resp);
          this.amount = resp.data[0].amount;
          this.message = resp.data[0].payment;

          /*this.date10 = resp.data[0].entered.slice(
            0,
            10
          );*/

          this.date10 = resp.data[0].entered;

          if (resp.data[0].flag == 0) {
            this.paymentStatus = "Pending";
          } else if (resp.data[0].flag == 1) {
            this.paymentStatus = "Completed";
          } else if (resp.data[0].flag == 2) {
            this.paymentStatus = "Deleted";
          } else if (resp.data[0].flag == 3) {
            this.paymentStatus = "Approved";
          }
        });
    },
    editWithdrawal(ID, status) {
      console.log(ID);
      this.editWithdraw = true;

      this.$refs.form.validate();

      if (!this.message || !this.amount || !this.date10) return false;

      let payload = {
        payment: this.message,
        amount: this.amount,
        flag: 1,
        create: this.date10,
      };
      axios
        .put(`${API_BASE_URL}/withdraw/${ID}`, payload, {
          headers: {
            accept: "application/json",
            "x-access-token": this.token.accessToken,
          },
        })
        .then((resp) => {
          this.getwithdrawal();
          this.message = "";
          this.amount = 0;
          this.HNTchanges = 0;
          this.requser = "";
          this.editWithdraw = false;
        });
    },
    closingWithdraw() {
      this.message = "";
      (this.investHotspot = 0), (this.amount = 0);
      this.HNTchanges = 0;
      this.requser = "";
      this.editWithdraw = false;
    },
    sendInvestment(amount, status, message, startdate,FirstTier,SecondTier,ThirdTier, newSecondTier, customTier) {
      this.$refs.form1.validate();

      if (!message || !startdate || !this.investHotspot) return false;

      let payload = {
        payment: message,
        amount: amount,
        flag: 1,
        hotspot: this.investHotspot,
        create: startdate,
        Tier1x:FirstTier,
        Tier3x:SecondTier,
        TierLiftime:ThirdTier,
        customTier: customTier
      };
      console.log(payload);
      axios
        .post(`${API_BASE_URL}/newinvestment/${this.uid}`, payload, {
          headers: {
            accept: "application/json",
            "x-access-token": this.token.accessToken,
          },
        })
        .then((resp) => {
          this.newinvest = false;
          this.getInvest();
          this.message = "";
          this.date9 = "";
          this.investHotspot = 0;
          this.amount = 0;
          this.getwithdrawal();
        });
    },
    viewInvestment(ID) {
      this.reqid = ID;
      console.log(ID);
      this.editInvest = true;
      axios
        .get(`${API_BASE_URL}/investment/${ID}`, {
          headers: {
            accept: "application/json",
            "x-access-token": this.token.accessToken,
          },
        })
        .then((resp) => {
          this.investHotspot = resp.data[0].hotspot;
          this.amount = resp.data[0].amount;
          this.FirstTier = resp.data[0].Tier1x,
        this.SecondTier =resp.data[0].Tier3x
        this.ThirdTier=resp.data[0].TierLiftime
         this.customTier = resp.data[0].CustomTier
         this.xTie=resp.data[0].Tierx

          /*this.date8 = resp.data[0].entered.slice(
            0,
            10
          );*/

          this.date8 = resp.data[0].entered;

          this.message = resp.data[0].payment;
          if (resp.data[0].flag == 0) {
            this.paymentStatus = "Pending";
          } else if (resp.data[0].flag == 1) {
            this.paymentStatus = "Completed";
          } else if (resp.data[0].flag == 2) {
            this.paymentStatus = "Deleted";
          } else if (resp.data[0].flag == 3) {
            this.paymentStatus = "Approved";
          }
        });
    },
    editInvestment(ID, status) {
      console.log(ID);
      this.$refs.form2.validate();

      if (!this.message || !this.amount || !this.investHotspot || !this.date8)
        return false;

      this.editInvest = true;

      let payload = {
        payment: this.message,
        amount: this.amount,
        flag: 1,
        hotspot: this.investHotspot,
        create: this.date8,
        Tier1x:this.FirstTier,
        Tier3x:this.SecondTier,
        TierLiftime:this.ThirdTier,
        customTier: this.customTier
      };

      console.log("payload == ", payload);

      axios
        .put(`${API_BASE_URL}/investment/${ID}`, payload, {
          headers: {
            accept: "application/json",
            "x-access-token": this.token.accessToken,
          },
        })
        .then((resp) => {
          this.getInvest();
          this.message = "";
          this.investHotspot = 0;
          this.amount = 0;
          this.HNTchanges = 0;
          this.requser = "";
          this.editInvest = false;
          this.getwithdrawal();
        });
    },
    closingInvestment() {
      this.message = "";
      this.amount = 0;
      this.HNTchanges = 0;
      this.date5 = "";
      this.requser = "";
      this.editInvest = false;
    },
    deleteInvestment(ID) {
      axios
        .delete(`${API_BASE_URL}/investment/${ID}`, {
          headers: {
            accept: "application/json",
            "x-access-token": this.token.accessToken,
          },
        })
        .then((resp) => {
          console.log("permanent deleted");
          this.editInvest = false;
          this.message = "";
          this.amount = 0;
          this.getInvest();
        });
    },
    deleteWithdrawal(ID) {
      axios
        .delete(`${API_BASE_URL}/withdraw/${ID}`, {
          headers: {
            accept: "application/json",
            "x-access-token": this.token.accessToken,
          },
        })
        .then((resp) => {
          console.log("permanent deleted");
          this.editWithdraw = false;
          this.message = "";
          this.amount = 0;
          this.getwithdrawal();
        });
    },
    deleteMessage(ID) {
      axios
        .delete(`${API_BASE_URL}/message/${ID}`, {
          headers: {
            accept: "application/json",
            "x-access-token": this.token.accessToken,
          },
        })
        .then((resp) => {
          console.log("permanent deleted");
          this.getchat();
        });
    },
    getInvest() {
      axios
        .get(`${API_BASE_URL}/investments/${this.uid}`, {
          headers: { accept: "application/json" },
        })
        .then((resp) => {
          this.investLog = resp.data;
          this.total = 0
          this.hotspotcount = 0

         
          for (var i in this.investLog) {
            this.total += parseInt(this.investLog[i].amount);
            this.hotspotcount += parseInt(this.investLog[i].hotspot);
            if (this.investLog[i].flag == 0) {
              this.investLog[i].flag = "Pending";
            } else if (this.investLog[i].flag == 1) {
              this.investLog[i].flag = "Completed";
            } else if (this.investLog[i].flag == 2) {
              this.investLog[i].flag = "Deleted";
            } else if (this.investLog[i].flag == 3) {
              this.investLog[i].flag = "Approved";
            }
          }

          this.investname=this.investLog.map(({ payment }) => payment)
          
          this.getJAGstatus();
        });
    },
    getOracleValue() {
      axios
        .get(`${API_HELIUM_URL}/v1/oracle/prices/current`, {
          headers: {
            accept: "application/json",
          },
        })
        .then((resp) => {
          this.oracleprice = resp.data.data.price;
        });
    },
    getwithdrawal() {
      axios
        .get(`${API_BASE_URL}/withdrawal/${this.uid}`, {
          headers: { accept: "application/json" },
        })
        .then((resp) => {
          var aa = {};
          this.paidouttotal = 0;
          this.withdrawalLog = resp.data;
          for (var i in this.withdrawalLog) {
            if (aa[this.withdrawalLog[i].payment]) {
              console.log("empty");
            } else {
              aa[this.withdrawalLog[i].payment] = 0;
            }
            aa[this.withdrawalLog[i].payment] += parseInt(
              this.withdrawalLog[i].amount
            );
            this.paidouttotal += parseInt(this.withdrawalLog[i].amount);
            
            if (this.withdrawalLog[i].flag == 0) {
              this.withdrawalLog[i].flag = "Pending";
            } else if (this.withdrawalLog[i].flag == 1) {
              this.withdrawalLog[i].flag = "Completed";
            } else if (this.withdrawalLog[i].flag == 2) {
              this.withdrawalLog[i].flag = "Deleted";
            } else if (this.withdrawalLog[i].flag == 3) {
              this.withdrawalLog[i].flag = "Approved";
            }
          }
          this.gettier(aa);
          this.withdrawstotal = aa;
        });
    },
    populateField(arr) {
      console.log(arr);
      try {
        this.contact.paypal_address = "";
      } catch (err) {
        console.log("empty value");
      }
      try {
        this.contact.zelle_address = arr.zelle;
      } catch (err) {
        console.log("empty value");
      }
      try {
        this.contact.btc_address = arr.BTC;
      } catch (err) {
        console.log("empty value");
      }
      try {
        this.contact.eth_address = arr.ETH;
      } catch (err) {
        console.log("empty value");
      } 
      try {
        this.contact.hnt_address = arr.HNT;
      } catch (err) {
        console.log("empty value");
      }
      try {
        this.contact.phone = arr.Mobile;
      } catch (err) {
        console.log("empty value");
      }
      try {
        this.contact.venmo_address = arr.Venmo;
      } catch (err) {
        console.log("empty value");
      }
    },
    populateAdminField(arr) {
      try {
        this.contact.Wifiname = arr.Wifiname;
      } catch (err) {
        console.log("empty value");
      }
      try {
        this.contact.MailingCity = arr.MailingCity;
      } catch (err) {
        console.log("empty value");
      }
      try {
        this.contact.MailingCountry = arr.MailingCountry;
      } catch (err) {
        console.log("empty value");
      }
      try {
        this.contact.MailingState = arr.MailingState;
      } catch (err) {
        console.log("empty value");
      }
      try {
        this.contact.MailingStreet = arr.MailingStreet;
      } catch (err) {
        console.log("empty value");
      }
      try {
        this.contact.MailingZipCode = arr.MailingZipCode || '';
      } catch (err) {
        console.log("empty value");
      }
      try {
        this.contact.Wifipassword = arr.Wifipassword;
      } catch (err) {
        console.log("empty value");
      }
      try {
        this.contact.AntennaType = arr.AntennaType;
      } catch (err) {
        console.log("empty value");
      }
      try {
        this.contact.AntennaLocation = arr.AntennaLocation;
      } catch (err) {
        console.log("empty value");
      }
      try {
        this.contact.Hotspotconnection = arr.Hotspotconnection;
      } catch (err) {
        console.log("empty value");
      }
      try {
        this.contact.LastName = arr.LastName;
      } catch (err) {
        console.log("empty value");
      }
      try {
        this.contact.FirstName = arr.FirstName;
      } catch (err) {
        console.log("empty value");
      }
      try {
        this.contact.rank = arr.rank;
      } catch (err) {
        console.log("empty value");
      }
    },
    save(flag) {
      let validationFlag = false;
      if(flag == "payment_information") {
        if (this.$refs.paymentInformationForm.validate()) {
          validationFlag = true;
        }
      } else {
        if (this.$refs.contactInformationForm.validate()) {
          validationFlag = true;
        }
      }
      if(validationFlag) {
        let payload = {
          FirstName: this.contact.FirstName,
          LastName: this.contact.LastName,
          hnt: this.contact.hnt_address,
          admin: this.token.email,
          paypal: "",
          zelle: this.contact.zelle_address,
          mobile: this.contact.phone,
          btc: this.contact.btc_address,
          eth: this.contact.eth_address,
          venmo: this.contact.venmo_address,
          MailingCity: this.contact.MailingCity,
          MailingCountry :this.contact.MailingCountry,
          MailingState: this.contact.MailingState,
          MailingStreet: this.contact.MailingStreet,
          MailingZipCode: this.contact.MailingZipCode,
          rank: this.contact.rank,
          flag: flag
        };
        axios.put(`${API_BASE_URL}/payment/${this.uid}`, payload).then(res=>{
          if(res.status == 200){
            this.sucessAlert = true;
          }
        });
      }
    },
    updateOwnedHotspot() {
      var i;
      var arr = [];
      var x;
      var serverlist = [];
      var serverall = [];
      axios
        .get(`${API_BASE_URL}/user/hotspot/${this.uid}`, {
          headers: {
            accept: "application/json",
          },
        })
        .then((resp) => {
          var hotspotAll = resp.data;
          for (x = 0; x < hotspotAll.length; x++) {
            serverlist.push(hotspotAll[x].Haddress);
            serverall.push(hotspotAll[x]);
          }
          for (i = 0; i < this.ownedhotspot.length; i++) {
            axios
              .get(
                `${API_HELIUM_URL}/v1/hotspots/name/${this.ownedhotspot[i]}`,
                {
                  headers: {
                    accept: "application/json",
                  },
                }
              )
              .then((resp) => {
                arr.push(resp.data.data[0].address);
                this.DelUpRecord(
                  serverlist,
                  serverall,
                  resp.data.data[0].address
                );
                this.dialog = false;
              });
          }
        });
    },
    DelUpRecord(ServerList, AllList, Hotspots) {
      if (ServerList.includes(Hotspots)) {
        console.log("mantenuto il valore");
      } else {
        axios
          .post(`${API_BASE_URL}/hotspot/${this.uid}`, {
            haddress: Hotspots,
          })
          .then((resp) => {
            this.text = "Save Successful";
            this.snackbar = true;
            this.getuid();
          });
      }
    },
    overRide() {
      if (confirm("Do you really want to override it?")) {
        axios
          .get(
            `${API_BASE_URL}/removePrimaryHotspot/${this.removePrimaryHotspot}/${this.date1}`,
            {
              headers: {
                accept: "application/json",
                "x-access-token": this.token.accessToken,
              },
            }
          )
          .then((resp) => {
            this.removePrimaryHotspot = "";
            this.createHotspot();
            this.hasowner = false;
          });
      }
    },
    changeNumberFormat(value){
      if(value && !isNaN(value) && value != 0) return value.toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,");
      return value;  
    }
  },
};
</script>
<style>
.contact-icon {
  background: white;
  border-radius: 50%;
  padding: 10px;
  margin: 20px;
}
</style>